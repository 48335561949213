import {defineStore} from "pinia";
import {getTarifDaten, hasEtAndZtPreise} from "@/api/TarifeDatenApi";
import {ITarif} from "@/types/Tarif";
import {PossibleZaehlerEnum} from "@/types/PossibleZaehlerEnum";

interface State {
    tarife: ITarif[];
    loading: boolean;
    possibleZaehlerEnum: PossibleZaehlerEnum;
}

export const useStore = defineStore("tarifStore", {
    state: (): State => {
        return {
            tarife: [],
            loading: false,
            possibleZaehlerEnum: PossibleZaehlerEnum.NONE
        };
    },
    actions: {
        async getTarifDaten(akquiseSession: string) {
            this.loading = true;
            const tarifDaten = await getTarifDaten(akquiseSession);
            if (tarifDaten) {
                this.tarife = tarifDaten;
            }
            this.loading = false;
        },
        async hasEtAndZtPreise(akquiseSession: string) {
            const possibleZaehlerEnum = await hasEtAndZtPreise(akquiseSession);
            if (possibleZaehlerEnum) {
                this.possibleZaehlerEnum = possibleZaehlerEnum;
            }
        },
    },
    getters: {
        getTarifById: (state) => {
            return (tarifId: number): ITarif | null => {
                const tarife = state.tarife as ITarif[];
                const tarif = tarife.find((tarif) => tarif.tarifId === tarifId);
                if (tarif) {
                    return tarif;
                } else {
                    return null;
                }
            }
        },
    }
});

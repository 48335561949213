import {defineStore} from "pinia";

interface State {
    jentisEnabled: boolean;
}

export const useStore = defineStore("jentisStore", {
    state: (): State => {
        return {
            jentisEnabled: false
        };
    }
});

import {createRouter, createWebHistory, RouteRecordRaw, RouterScrollBehavior,} from "vue-router";
import TarifrechnerView from "@/views/TarifrechnerView.vue";
import KontaktdatenView from "@/views/KontaktdatenView.vue";
import TarifrechnerDetailView from "@/views/TarifrechnerDetailView.vue";
import LieferadresseView from "@/views/LieferadresseView.vue";
import EmailVerifizierungView from "@/views/EmailVerifizierungView.vue";
import ZahlungsDetailsView from "@/views/ZahlungsDetailsView.vue";
import ZaehlerDetailsView from "@/views/ZaehlerDetailsView.vue";
import ValidationGuard from "@/router/ValidationGuard";
import ZusammenfassungView from "@/views/ZusammenfassungView.vue";
import WechselView from "@/views/WechselView.vue";
import {AkquiseStepEnum} from "@/types/AkquiseStepEnum";
import BestellbestaetigungView from "@/views/BestellbestaetigungView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        name: "Tarifrechner",
        path: "/",
        beforeEnter: ValidationGuard,
        component: TarifrechnerView,
        meta: {
            step: AkquiseStepEnum.VERBRAUCH,
            showStepper: false,
            showBackButton: true,
            title: 'Sichern Sie sich Ihren Tarif mit 100% Grünstrom | Jetzt zu GP JOULE wechseln'
        }
    },
    {
        name: "TarifrechnerDetail",
        path: "/tarife",
        beforeEnter: ValidationGuard,
        component: TarifrechnerDetailView,
        meta: {
            step: AkquiseStepEnum.TARIF_AUSWAHL,
            showStepper: false,
            showBackButton: true,
            title:"Sichern Sie sich Ihren Tarif mit 100% Grünstrom | Jetzt zu GP JOULE wechseln"
        }
    },
    {
        name: "Kontaktdaten",
        path: "/kontaktdaten",
        beforeEnter: ValidationGuard,
        component: KontaktdatenView,
        meta: {
            step: AkquiseStepEnum.KONTAKTDATEN,
            showStepper: true,
            showBackButton: false,
            title:"Kontaktdaten | Jetzt zu GP JOULE wechseln"
        }
    },
    {
        name: "Lieferadresse",
        path: "/lieferadresse",
        beforeEnter: ValidationGuard,
        component: LieferadresseView,
        meta: {
            step: AkquiseStepEnum.ADRESSE,
            showStepper: true,
            showBackButton: false,
            title: "Adresse | Jetzt zu GP JOULE wechseln"
        }
    },
    {
        name: "Wechsel",
        path: "/wechsel",
        beforeEnter: ValidationGuard,
        component: WechselView,
        meta: {
            step: AkquiseStepEnum.LIEFERBEGINN,
            showStepper: true,
            showBackButton: false,
            title: "Wechseldetails | Jetzt zu GP JOULE wechseln"
        }
    },
    {
        name: "Zahlungsdetails",
        path: "/zahlung",
        beforeEnter: ValidationGuard,
        component: ZahlungsDetailsView,
        meta: {
            step: AkquiseStepEnum.ZAHLUNG,
            showStepper: true,
            showBackButton: false,
            title: "Zahlung | Jetzt zu GP JOULE wechseln"
        }
    },
    {
        name: "Zaehler",
        path: "/zaehler",
        beforeEnter: ValidationGuard,
        component: ZaehlerDetailsView,
        meta: {
            step: AkquiseStepEnum.ZAEHLER,
            showStepper: true,
            showBackButton: false,
            title: "Zähler | Jetzt zu GP JOULE wechseln"
        }
    },
    {
        name: "EmailVerifizierung",
        path: "/verifizierung",
        beforeEnter: ValidationGuard,
        component: EmailVerifizierungView,
        meta: {
            step: AkquiseStepEnum.EMAIL_VERIFIZIEREN,
            showStepper: true,
            showBackButton: false,
            title: 'Verifizierung | Jetzt zu GP JOULE wechseln',
        }
    },
    {
        name: "Zusammenfassung",
        path: "/zusammenfassung",
        component: ZusammenfassungView,
        meta: {
            step: AkquiseStepEnum.ZUSAMMENFASSUNG,
            showStepper: true,
            showBackButton: false,
            title:"Zusammenfassung | Jetzt zu GP JOULE wechseln"
        }
    },
    {
        name: "Bestellbestaetigung",
        path: "/bestellbestaetigung",
        component: BestellbestaetigungView,
        meta: {
            step: AkquiseStepEnum.BESTAETIGUNG,
            showStepper: true,
            showBackButton: true,
            title:'Vielen Dank für Ihre Bestellung | GP JOULE'
        }
    },
    {
        name: "PageNotFound",
        path: "/:pathMatch(.*)*",
        redirect: () => {
            return {path: "/"};
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: (() => {
        return {top: 0};
    }) as RouterScrollBehavior,
    routes,
});

export default router;

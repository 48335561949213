<script setup lang="ts">

import JouleInputSwitch from "@/components/JouleInputSwitch.vue";
import JouleInputField from "@/components/JouleInputField.vue";
import {ref, defineEmits} from "vue";
import {useStore} from "@/store/AkquiseDatenStore";
import {useStore as useStepValidationStore} from "@/store/StepValidationStore";
const emits = defineEmits(['enter'])
const akquiseDatenStore = useStore();
const stepValidationStore = useStepValidationStore();
const aktionscodeActive = ref(akquiseDatenStore.akquiseDaten.aktionscode != null && akquiseDatenStore.akquiseDaten.aktionscode != "")

const validate = async () => {
  await akquiseDatenStore.postAkquiseDaten();
  await stepValidationStore.validate();
}

const aktionscodeActiveChanged = () => {
  if (!aktionscodeActive.value) {
    akquiseDatenStore.akquiseDaten.aktionscode = '';
    validate();
  }
};

</script>

<template>
  <div class="mb-2">
    <JouleInputSwitch input-id="aktionscodeInputSwitch" @input-changed="aktionscodeActiveChanged"
                      v-model="aktionscodeActive" label="Aktionscode eingeben"></JouleInputSwitch>
    <div v-if="aktionscodeActive">
      <JouleInputField name="aktionscode"
                       v-model="akquiseDatenStore.akquiseDaten.aktionscode" icon="confirmation_number"
                       :error-text="akquiseDatenStore.akquiseDaten.aktionscode ? stepValidationStore.getAnyErrorByFieldName('aktionscode') : ''"
                       :success-text="akquiseDatenStore.akquiseDaten.aktionscodeSuccessMeldung"
                       :input-valid="stepValidationStore.akquiuseDatenValidation.aktionscodeValid"
                       placeholder="Aktionscode" @input-changed="validate" @enter="$emit('enter')"></JouleInputField>
    </div>
  </div>
</template>

<style scoped lang="scss">


</style>

<script setup lang="ts">
import {onMounted, ref, defineProps, PropType} from 'vue';
  const isMounted = ref(false);

  defineProps({
    heading: {
      type: String,
      required: true,
    },
    labels: {
      type: Array as PropType<string[]>,
      required: true,
    },
    values: {
      type: Array as PropType<string[]>,
      required: true,
    },
    bottomLabel: {
      type: String,
      required: false,
    },
    bottomValue: {
      type: String,
      required: false,
    },
    unit: {
      type: String,
      required: false,
      default: '',
    },
  });

  onMounted(() => {
    isMounted.value = true;
  });
</script>
<template>
  <div v-if="isMounted" class="tooltip">
    <div class="heading">{{ heading }}</div>
    <div class="body">
      <div v-for="(label, i) in labels" v-bind:key="label" class="row">
        <div class="left">{{ label }}:</div>
        <div class="right">{{ values[i].length === 0 ? "0,00 ct" : values[i] + unit }}</div>
      </div>
      <div v-if="bottomLabel && bottomValue" class="row bottom-row">
        <div class="left">{{ bottomLabel }}:</div>
        <div class="right">{{ bottomValue + unit }}</div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .tooltip {
    background-color: var(--black-07);
    padding: 15px;
    width: 155px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 12px;
    .heading {
      font-weight: 600;
      margin-bottom: 10px;
    }
    .body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      .row {
        width: 100%;
        .left {
          float: left;
        }
        .right {
          float: right;
        }
      }
      .bottom-row {
        padding-top: 5px;
        border-top: 1px solid var(--grey);
        font-weight: 600;
      }
    }
  }
</style>

export const euroValueFormatter = (value: number): string => {
  return new Intl.NumberFormat('de', { style: 'currency', currency: 'EUR' }).format(value);
};

export const centValueFormatter = (value: number): string => {
  if (value) {
    return value.toFixed(2).replace('.', ',').concat(' ct');
  } else {
    return ""
  }
};

export const kwhValueFormatter = (value: number): string => {
  return (value / 1000).toString().replace('.', ',').concat(' kWh');
};

<script setup lang="ts">
import {defineModel, defineProps, onMounted, PropType, ref, watch} from "vue";

const props = defineProps({
  timer: {
    type: Number,
    default: () => 90
  },
})
const timerVal = ref(props.timer);

const startInterval = () => {
  timerVal.value = props.timer;
  const interval = setInterval(() => {
    if (timerVal.value > 0) {
      if (model.value) {
        model.value = false;
      }
      timerVal.value--;
    } else {
      clearInterval(interval);
      model.value = true;
    }
  }, 1000);
}

onMounted(() => {
  startInterval();
});

const model = defineModel({
  type: Boolean,
  required: true
});

watch(model, (newValue) => {
  if (!newValue) {
    startInterval();
  }
});

function formatTime(seconds:number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}
</script>

<template>
<div v-if="timerVal > 1" class="timer-wrapper">{{ formatTime(parseFloat(timerVal.toPrecision(timerVal > 10 ? 2 :1))) }}</div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";
.timer-wrapper{
  background:var(--black);
  color:var(--white);
  padding: 3px 10px 0 10px;
  border-radius: 4px;
}
</style>

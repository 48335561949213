import {useStore} from "@/store/AkquiseDatenStore";
import {useStore as useTarifDatenStore} from "@/store/TarifDatenStore";
import {useStore as useJentisStore} from "@/store/JentisStore";

declare const _jts: any;

export const handleJentis = async (): Promise<void> => {
    const jentisStore = useJentisStore();
    if (jentisStore.jentisEnabled) {
        const akquiseDatenStore = useStore();
        const tarifDatenStore = useTarifDatenStore();
        const variante = akquiseDatenStore.akquiseDaten.variante;
        const kundengruppe = akquiseDatenStore.akquiseDaten.firma ? 'B2B' : 'B2C';
        let jentisName = '';
        let arbeitspreis = null;
        let grundpreis = null;
        let jentisId = variante + "_" + kundengruppe
        if (akquiseDatenStore.akquiseDaten.akquiseSession && akquiseDatenStore.akquiseDaten.tarifId) {
            if (tarifDatenStore.tarife.length === 0) {
                await tarifDatenStore.getTarifDaten(akquiseDatenStore.akquiseDaten.akquiseSession);
            }
            const tarif = tarifDatenStore.getTarifById(akquiseDatenStore.akquiseDaten.tarifId);
            if (tarif) {
                jentisId = jentisId + "_" + tarif.tarifTypEnum.toLowerCase();
                jentisName = tarif.tarifTypEnum.toLowerCase();
                arbeitspreis = tarif.verbrauchspreisTotal.toFixed(2);
                grundpreis = tarif.grundpreisTotal.toFixed(2);
            }
        }

        _jts.push({track: "pageview"});
        let value;
        if (jentisName.length > 0) {
            value = {
                "track": "product",
                "id": jentisId,
                "name": jentisName,
                "category": kundengruppe,
                "kwh": akquiseDatenStore.akquiseDaten.verbrauch,
                "arbeitspreis": arbeitspreis,
                "grundpreis": grundpreis
            };
        } else {
            value = {
                "track": "product",
                "id": jentisId,
                "category": kundengruppe,
                "kwh": akquiseDatenStore.akquiseDaten.verbrauch,
            };
        }
        _jts.push(value);
        _jts.push({track: "submit"});
    }
};

export const buttonClicked = async (value: string): Promise<void> => {
    const jentisStore = useJentisStore();
    if (jentisStore.jentisEnabled) {
        _jts.push({
            "track": "event",
            "name": "button-click",
            "value": value
        });
        _jts.push({track: "submit"});
    }
};

export const handleSimpleJentis = async (): Promise<void> => {
    const jentisStore = useJentisStore();
    if (jentisStore.jentisEnabled) {
        const akquiseDatenStore = useStore();
        const variante = akquiseDatenStore.akquiseDaten.variante;
        const kundengruppe = akquiseDatenStore.akquiseDaten.firma ? 'B2B' : 'B2C';
        const jentisId = variante + "_" + kundengruppe

        _jts.push({track: "pageview"});
        _jts.push({
            "track": "product",
            "id": jentisId,
            "category": kundengruppe,
            "kwh": akquiseDatenStore.akquiseDaten.verbrauch,
        });
        _jts.push({track: "submit"});
    }
};

export const handleBasicJentis = (): void => {
    const jentisStore = useJentisStore();
    if (jentisStore.jentisEnabled) {
        _jts.push({track: "pageview"});
        _jts.push({track: "submit"});
    }
};

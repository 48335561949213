export interface IVerbrauchsVorauswahl {
    flaecheData: IVerbrauchsVorauswahlOption[],
    personData: IVerbrauchsVorauswahlOption[],
    laufLeistungData: IVerbrauchsVorauswahlOption[],
}

export interface IVerbrauchsVorauswahlOption {
    label: string,
    value: number
}

export const VerbrauchsVorauswahl: IVerbrauchsVorauswahl = {
    flaecheData: [
        {label: "45 m²", value: 2750},
        {label: "90 m²", value: 5500},
        {label: "115 m²", value: 7500},
        {label: "175 m²", value: 11000},
    ],
    personData: [
        {label: "1 Person", value: 1500},
        {label: "2 Personen", value: 2500},
        {label: "3 Personen", value: 3500},
        {label: "4 Personen", value: 4500},
        {label: "5 Personen", value: 5000},
        {label: "6 Personen", value: 5500}
    ],
    laufLeistungData: [
        {label: "5.000 km", value: 1000},
        {label: "10.000 km", value: 2000},
        {label: "15.000 km", value: 3000},
        {label: "20.000 km", value: 4000},
        {label: "25.000 km", value: 5000},
    ],
}

import {ITarif} from "@/types/Tarif";
import {PossibleZaehlerEnum} from "@/types/PossibleZaehlerEnum";

export const getTarifDaten = async (akquiseSession: string): Promise<ITarif[]> => {
     return fetch("/api/tarife/akquiseSession/" + akquiseSession, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return [];
        }
    }, () => {
         return [];
     });
};

export const hasEtAndZtPreise = async (akquiseSession: string): Promise<PossibleZaehlerEnum> => {
     return fetch("/api/tarife/akquiseSession/" + akquiseSession + "/has-et-and-zt-preise", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return PossibleZaehlerEnum.NONE;
        }
    }, () => {
         return PossibleZaehlerEnum.NONE;
     });
};

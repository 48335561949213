<script setup lang="ts">
import Card from "primevue/card";
import Button from "primevue/button";
import {useStore} from "@/store/AkquiseDatenStore";
import {useStore as useVStore} from "@/store/StepValidationStore";
import router from "@/router";
import ContentBox from "@/components/ContentBox.vue";
import JouleTimerButton from "@/components/JouleTimerButton.vue";
import {resendVerifizierung, sendVerifizierung} from "@/api/AkquiseDatenApi";
import {handleJentis} from "@/utils/JentisService";
import {ref} from "vue";
import dayjs from "dayjs";
import ErrorBox from "@/components/ErrorBox.vue";

handleJentis();

let akquiseDatenStore = useStore();
let stepValidationStore = useVStore();

const back = () => {
  router.push({name: "Kontaktdaten"});
}

const resend = () => {
  timerVal.value = 90;
  resendVerifizierung()
}
const timerVal = ref()
const timerIsLoaded = ref(false)
const setTimer = async () => {
  // Diese Api sendet nur beim ersten mal eine Email, bei weiteren -> resendVerification
  await sendVerifizierung();
  await akquiseDatenStore.getAkquiseDaten();
  if (akquiseDatenStore.akquiseDaten.emailVerifiziert) {
    router.push({name: "Zusammenfassung"});
  } else {
    const date1 = Date.now();
    if (Math.abs(date1 - dayjs(akquiseDatenStore.akquiseDaten.verifizierungsEmailSend!).valueOf()) / 1000 < 90) {
      timerVal.value = 90 - (Math.abs(dayjs(akquiseDatenStore.akquiseDaten.verifizierungsEmailSend!).valueOf() - date1) / 1000);
    } else {
      timerVal.value = 0;
    }
    timerIsLoaded.value = true;
  }
}
setTimer();
</script>

<template>
  <div class="card-wrapper">
    <Card class="card">
      <template #title>
        <div class="card-headline-small">Bitte verifizieren Sie Ihre E-Mail-Adresse</div>
      </template>
      <template #content>
        <div>
          <ErrorBox class="mb-2" v-if="stepValidationStore.getErrorByFieldName('email_verifizierung')">
            <template #headline>
              <div class="error-box-headline mb-2">Ihre E-Mail-Adresse ist noch nicht verifiziert.</div>
            </template>
            <template #content>
              <div class="error-box-content">Bitte klicken Sie auf den Link, den Sie von uns per E-Mail erhalten haben.
                Die angezeigte E-Mail-Adresse ist nicht korrekt? Dann klicken Sie bitte auf “Vertippt? E-Mail-Adresse
                bearbeiten” um diese zu bearbeiten.
              </div>
            </template>
          </ErrorBox>
          <div class="mb-2">
            <ContentBox>
              <template #content>
                <div class="subline-text mb-1">Wir haben Ihnen eine E-Mail an folgende E-Mail-Adresse gesendet, um diese
                  zu verifizieren und Ihre Bestellung abzuschließen:
                </div>
                <div class="flex-row-centered">
                  <i class="material-icons-round">mail</i>
                  <span>{{ akquiseDatenStore.akquiseDaten.email }}</span>
                </div>
              </template>
            </ContentBox>
          </div>
          <div class="mb-1">
            <JouleTimerButton :disabled="!timerIsLoaded" :timer="timerVal" label="E-Mail erneut versenden"
                              @click="resend"></JouleTimerButton>
          </div>
          <Button @click="back" severity="secondary" :outlined="true"
                  label="Vertippt? E-Mail-Adresse bearbeiten"></Button>
        </div>
      </template>
    </Card>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";
</style>

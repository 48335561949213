export enum VariantenEnum {
    ET = 'ET',
    ZT = 'ZT',
    WPgemMET = 'WPgemMET',
    WPgemMZT = 'WPgemMZT',
    WPgetrMET = 'WPgetrMET',
    WPgetrMZT = 'WPgetrMZT',
    NSgemMET = 'NSgemMET',
    NSgemMZT = 'NSgemMZT',
    NSgetrMET = 'NSgetrMET',
    NSgetrMZT = 'NSgetrMZT',
    MSgemMZT = 'MSgemMZT',
    MSgemMET = 'MSgemMET',
    MSgetrMET = 'MSgetrMET',
    MSgetrMZT = 'MSgetrMZT'
}

export const getByString = (varianteString: string): VariantenEnum | null=> {
    if (!varianteString) return null;
    if (varianteString === 'ET') {
        return VariantenEnum.ET
    } else if (varianteString === 'ZT') {
        return VariantenEnum.ZT
    } else if (varianteString === 'WPgemMET') {
        return VariantenEnum.WPgemMET
    } else if (varianteString === 'WPgemMZT') {
        return VariantenEnum.WPgemMZT
    } else if (varianteString === 'WPgetrMET') {
        return VariantenEnum.WPgetrMET
    } else if (varianteString === 'WPgetrMZT') {
        return VariantenEnum.WPgetrMZT
    } else if (varianteString === 'NSgemMET') {
        return VariantenEnum.NSgemMET
    } else if (varianteString === 'NSgemMZT') {
        return VariantenEnum.NSgemMZT
    } else if (varianteString === 'NSgetrMET') {
        return VariantenEnum.NSgetrMET
    } else if (varianteString === 'NSgetrMZT') {
        return VariantenEnum.NSgetrMZT
    } else if (varianteString === 'MSgemMZT') {
        return VariantenEnum.MSgemMZT
    } else if (varianteString === 'MSgemMET') {
        return VariantenEnum.MSgemMET
    } else if (varianteString === 'MSgetrMET') {
        return VariantenEnum.MSgetrMET
    } else if (varianteString === 'MSgetrMZT') {
        return VariantenEnum.MSgetrMZT
    } else {
        return null;
    }
};

<script setup lang="ts">
import {defineEmits, ref} from "vue";
import TarifrechnerKundenartSwitch from "@/components/TarifrechnerKundenartSwitch.vue";
import JouleInputNumber from "@/components/JouleInputNumber.vue";
import JouleInputField from "@/components/JouleInputField.vue";
import {useStore} from "@/store/AkquiseDatenStore";
import {useStore as useStepValidationStore} from "@/store/StepValidationStore";
import TarifrechnerAktionscode from "@/components/TarifrechnerAktionscode.vue";
import JouleIncrementInput from "@/components/JouleIncrementInput.vue";
import {VerbrauchsVorauswahl} from "@/types/VerbrauchVorauswahl";
import JouleAutoComplete from "@/components/JouleAutoComplete.vue";
import {getPlzOrt} from "@/api/PlzOrtApi";
import {IPlzOrt} from "@/types/PlzOrt";

const akquiseDatenStore = useStore();
const stepValidationStore = useStepValidationStore();
const validate = async () => {
  await akquiseDatenStore.postAkquiseDaten();
  await stepValidationStore.validate();
}
const emits = defineEmits(['user-changed-input', 'buttonChange','enter']);
const userChangedInput = async () => {
  emits("user-changed-input");
  await validate();
}

const query = async (searchTerm: string): Promise<IPlzOrt[]> => {
  if (searchTerm.length < 3) return [];
  return await getPlzOrt(searchTerm);
}

const formatInput = (value: IPlzOrt): string => {
  return value ? `${value.plz} ${value.ort}` : '';
}

const plz = ref(akquiseDatenStore.akquiseDaten.lieferadresse.plz || akquiseDatenStore.akquiseDaten.lieferadresse.ort ? {
  plz: akquiseDatenStore.akquiseDaten.lieferadresse.plz ? akquiseDatenStore.akquiseDaten.lieferadresse.plz : '',
  ort: akquiseDatenStore.akquiseDaten.lieferadresse.ort ? akquiseDatenStore.akquiseDaten.lieferadresse.ort : ''
} : null as IPlzOrt | null);

const plzChanged = async () => {
  if (plz.value) {
    akquiseDatenStore.akquiseDaten.lieferadresse.plz = plz.value.plz;
    akquiseDatenStore.akquiseDaten.lieferadresse.ort = plz.value.ort;
  } else {
    akquiseDatenStore.akquiseDaten.lieferadresse.plz = null;
    akquiseDatenStore.akquiseDaten.lieferadresse.ort = null;
  }
  await validate();
}
</script>

<template>
  <TarifrechnerKundenartSwitch @button-change="$emit('buttonChange')"></TarifrechnerKundenartSwitch>
  <div class="mb-2 flex-row non-aligned gap-1">
    <JouleAutoComplete :error-text="stepValidationStore.getErrorByFieldName('lieferadresse.plz')"
                       label="Wie lautet Ihre Postleitzahl?"
                       placeholder="Postleitzahl"
                       v-model="plz"
                       :input-valid="stepValidationStore.akquiuseDatenValidation.lieferadressePlzValid"
                       :query="query"
                       :input-formatter="formatInput"
                       :result-formatter="formatInput"
                       :queryInitial="akquiseDatenStore.akquiseDaten.lieferadresse.plz && akquiseDatenStore.akquiseDaten.lieferadresse.plz.length > 0 &&
                        (!akquiseDatenStore.akquiseDaten.lieferadresse.ort || akquiseDatenStore.akquiseDaten.lieferadresse.ort.length === 0)"
                       @input-changed="plzChanged"></JouleAutoComplete>
    <JouleInputNumber v-if="akquiseDatenStore.akquiseDaten.firma" name="verbrauch"
                      button-header="Wie hoch ist Ihr Verbrauch?"
                      placeholder="Verbrauch in kWh" suffix=" kWh"
                      @inputChanged="userChangedInput"
                      :error-text="stepValidationStore.getErrorByFieldName('verbrauch')"
                      v-model="akquiseDatenStore.akquiseDaten.verbrauch" icon="bolt" @enter="$emit('enter')"></JouleInputNumber>
  </div>
  <div class="mb-2 flex-row non-aligned gap-1" v-if="!akquiseDatenStore.akquiseDaten.firma">
    <JouleIncrementInput :data="VerbrauchsVorauswahl.personData" button-header="Wie groß ist Ihr Haushalt?" icon="person"
                         v-model="akquiseDatenStore.akquiseDaten.verbrauch" @inputChanged="userChangedInput"
    ></JouleIncrementInput>

    <JouleInputNumber name="verbrauch" button-header="Wie hoch ist Ihr Verbrauch?" placeholder="Verbrauch in kWh"
                      suffix=" kWh"
                      :error-text="stepValidationStore.getErrorByFieldName('verbrauch')"
                      @inputChanged="userChangedInput"
                      v-model="akquiseDatenStore.akquiseDaten.verbrauch" icon="bolt" @enter="$emit('enter')"></JouleInputNumber>
  </div>
  <TarifrechnerAktionscode @enter="$emit('enter')"></TarifrechnerAktionscode>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.input {
  flex: 1
}
</style>

import {defineStore} from "pinia";
import {IAkquiseDaten} from "@/types/AkquiseDaten";
import {
    confirmOrder,
    getAkquiseDaten,
    getAkquiseDatenByVerifizierungsCode,
    postAkquiseDaten,
    verifyEmail
} from "@/api/AkquiseDatenApi";
import {SparteEnum} from "@/types/SparteEnum";

interface State {
    akquiseDaten: IAkquiseDaten;
    hideVarianteSelect: boolean;
}

export const useStore = defineStore("userStore", {
    state: (): State => {
        return {
            akquiseDaten: {
                id: null,
                lieferadresse: {
                    id: null,
                    vorname: null,
                    nachname: null,
                    firma: null,
                    strasse: null,
                    hausNr: null,
                    plz: null,
                    ort: null,
                    adressZusatz: null
                },
                rechnungsadresse: {
                    id: null,
                    vorname: null,
                    nachname: null,
                    firma: null,
                    strasse: null,
                    hausNr: null,
                    plz: null,
                    ort: null,
                    adressZusatz: null
                },
                akquiseSession: null,
                firma: true,
                verbrauch: null,
                aktionscode: null,
                tarifId: null,
                ustId: null,
                telefonnummer: null,
                wechsel: true,
                aktuellerLieferant: null,
                wechseltermin: null,
                umzugstermin: null,
                abweichendeRechnungsadresse: false,
                malo: null,
                zaehlerNummer: null,
                lastschrift: true,
                kontoInhaber: null,
                iban: null,
                email: null,
                bestelltAm: null,
                variante: null,
                abschlag: null,
                emailVerifiziert: false,
                sparte: SparteEnum.STROM,
                gemeinsameMessung: false,
                eintarifzaehler: true,
                asFastAsPossible: true,
                aktionscodeSuccessMeldung: null,
                optIn: false,
                wechselBelieferungCheckbox: false,
                geburtsdatum: null,
                grundpreis: null,
                arbeitspreis: null,
                verifizierungsEmailSend: null,
                referrerLink: null
            },
            hideVarianteSelect: false
        };
    },
    actions: {
        async getAkquiseDaten() {
            const akquiseDaten = await getAkquiseDaten();
            if (akquiseDaten) {
                this.akquiseDaten = akquiseDaten;
            }
        },
        async getAkquiseDatenByVerifizierungsCode(verifizierungsCode: string) {
            const akquiseDaten = await getAkquiseDatenByVerifizierungsCode(verifizierungsCode);
            if (akquiseDaten) {
                this.akquiseDaten = akquiseDaten;
            }
        },
        async confirmOrder(grundpreis: number, arbeitspreis: number) {
            const akquiseDaten = await confirmOrder(this.akquiseDaten.akquiseSession
                ? this.akquiseDaten.akquiseSession
                : "",
                grundpreis,
                arbeitspreis);
            if (akquiseDaten) {
                this.akquiseDaten = akquiseDaten;
            }
        },
        async verifyEmail(verifizierungsCode: string) {
            const akquiseDaten = await verifyEmail(verifizierungsCode);
            if (akquiseDaten) {
                this.akquiseDaten = akquiseDaten;
            }
        },
        async postAkquiseDaten() {
            const akquiseDaten = await postAkquiseDaten(this.akquiseDaten);
            if (akquiseDaten) {
                this.akquiseDaten = akquiseDaten;
            }
        }
    },
});

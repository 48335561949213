<script setup lang="ts">

</script>

<template>
  <div class="footer-wrapper mb-60-px">
    <a href="https://www.plus-gp-joule.de/kontakt" target="_blank">Kontakt</a>
    <a href="https://www.plus-gp-joule.de/faq" target="_blank">FAQ</a>
    <a href="https://www.plus-gp-joule.de/strommix" target="_blank">Strommix</a>
    <a href="https://www.plus-gp-joule.de/agb" target="_blank">AGB</a>
    <a href="https://www.plus-gp-joule.de/impressum" target="_blank">Impressum</a>
    <a href="https://www.plus-gp-joule.de/datenschutz" target="_blank">Datenschutz</a>
    <a href="https://www.plus-gp-joule.de/kuendigung" target="_blank">Kündigung</a>
  </div>

</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";
a{
  color: var(--gpj-grey-70, var(--gpj-grey-70, #888A8B));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  text-decoration: none/* 120% */
}
.footer-wrapper{
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 15px 30px;
  flex-wrap: wrap;
  padding: 0 15px 0 15px;

}
</style>

<script setup lang="ts">
</script>

<template>
  <div class="error-box-wrapper">
    <div>
      <slot name="headline"></slot>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.error-box-wrapper {
  width:100%;
  border-radius: 4px;
  padding: 15px;
  position: relative;
  background:var(--error-color);
}

</style>
<script setup lang="ts">
import ContentBox from "@/components/ContentBox.vue";
import Card from "primevue/card";
import { useStore } from "@/store/AkquiseDatenStore";
import { useStore as useTarifDatenStore } from "@/store/TarifDatenStore";
import JouleButton from "@/components/JouleButton.vue";
import router from "@/router";
import { useRoute } from "vue-router";
import {computed, ref} from "vue";
import { ITarif } from "@/types/Tarif";
import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import {centValueFormatter, euroValueFormatter} from "@/utils/NumberFormatter";
import { ITarifTypEnum } from "@/types/TarifTypEnum";
import {SparteEnum} from "@/types/SparteEnum";
import {handleJentis} from "@/utils/JentisService";

const akquiseDatenStore = useStore();
const tarifDatenStore = useTarifDatenStore();
const route = useRoute();
const collapsed = ref(true as boolean);
const currentTarif = ref({} as ITarif);

const loadInitialData = async () => {
  if (route.query && route.query.code) {
    await akquiseDatenStore.getAkquiseDatenByVerifizierungsCode(
      route.query.code as string
    );
  }

  if (akquiseDatenStore.akquiseDaten.akquiseSession) {
    await tarifDatenStore.getTarifDaten(
      akquiseDatenStore.akquiseDaten.akquiseSession
    );
  }
  if (akquiseDatenStore.akquiseDaten.tarifId) {
    currentTarif.value = tarifDatenStore.getTarifById(
      akquiseDatenStore.akquiseDaten.tarifId
    );
  }

  if (!akquiseDatenStore.akquiseDaten.id || !akquiseDatenStore.akquiseDaten.bestelltAm) {
    await router.push({name: "Zusammenfassung"});
  } else {
    // only handle Jentis if the user actually is allowed to be on the BestellbestaetigungView page
    handleJentis();
  }
};
loadInitialData();

const formatDate = (date: string) => {
  return dayjs(date, "YYYY-MM-DD").format("DD.MM.YYYY");
};
var opt = {
  margin: 1,
  filename: "Ihre-GP-JOULE-Bestellung.pdf",
  image: { type: "jpeg", quality: 0.98 },
  html2canvas: { scale: 2 },
  jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
};
const generatePdf = async () => {
  var element = document.getElementById("pdfId") as HTMLElement;
  element.style.display = "block";
  await html2pdf(element, opt);
  element.style.display = "none";
  console.log(element);
};

const sparteLabel = computed((): string => {
  if (akquiseDatenStore.akquiseDaten.sparte === SparteEnum.STROM) {
    if (akquiseDatenStore.akquiseDaten.firma) {
      return currentTarif.value.tarifname + ' Gewerbestrom '
    } else {
      return currentTarif.value.tarifname + ' Haushaltsstrom '
    }
  } else if (akquiseDatenStore.akquiseDaten.sparte === SparteEnum.WAERMEPUMPE || akquiseDatenStore.akquiseDaten.sparte === SparteEnum.NACHTSPEICHERHEIZUNG) {
    return currentTarif.value.tarifname + ' Wärmestrom '
  } else if (akquiseDatenStore.akquiseDaten.sparte === SparteEnum.AUTOSTROM) {
    return currentTarif.value.tarifname + ' Autostrom '
  } else {
    return '';
  }
})

</script>

<template>
  <div class="cards">
    <div class="card-wrapper">
      <Card class="card">
        <template #title>
          <div class="card-headline">Vielen Dank für Ihre Bestellung!</div>
        </template>
        <template #content>
          <div class="mb-2">
            <content-box>
              <template #content>
                <div class="subline-text">
                  Ihr Wechsel wird nun von uns vorbereitet. Sie erhalten in der
                  Zwischenzeit eine E-Mail mit der Zusammenfassung Ihrer
                  Bestellung mit allen von Ihnen gemachten Angaben an folgende
                  E-Mail-Adresse:
                </div>
                <div class="flex-row-centered content-box-text-bold-grey">
                  <div class="material-icons-round">mail</div>
                  <div>{{ akquiseDatenStore.akquiseDaten.email }}</div>
                </div>
              </template>
            </content-box>
          </div>
          <div>
            <content-box>
              <template #content>
                <div
                  class="toggle-panel-header flex-row"
                  @click="collapsed = !collapsed"
                >
                  <div class="small-bold">Zusammenfassung</div>
                  <i class="material-icons-round">{{
                    collapsed ? "add" : "remove"
                  }}</i>
                </div>
                <div v-if="!collapsed">
                  <div class="mb-2">
                    <div class="small-bold mt-2 mb-1">Vertragsdaten</div>
                    <div class="content-box-content content-box-text-bold-grey">
                      <div class="flex-row-together mb-1"
                           v-if="akquiseDatenStore.akquiseDaten.firma">
                          <i class="material-icons-round mr-10-px">factory</i>
                          <span>{{
                            akquiseDatenStore.akquiseDaten.lieferadresse.firma
                          }}</span>
                      </div>

                      <div
                        class="flex-row-together mb-1"
                        v-if="
                          akquiseDatenStore.akquiseDaten.lieferadresse.nachname
                        "
                      >
                        <i class="material-icons-round mr-10-px">person</i>
                        <span
                          >{{
                            akquiseDatenStore.akquiseDaten.lieferadresse.vorname
                          }}
                          {{
                            akquiseDatenStore.akquiseDaten.lieferadresse
                              .nachname
                          }}</span
                        >
                      </div>
                      <div
                        class="flex-row-together mb-1"
                        v-if="
                          akquiseDatenStore.akquiseDaten.geburtsdatum
                        "
                      >
                        <i class="material-icons-round mr-10-px">event</i>
                        <span
                          >{{
                            formatDate(akquiseDatenStore.akquiseDaten.geburtsdatum)
                          }}</span
                        >
                      </div>
                      <div class="flex-row-together mb-1">
                        <i class="material-icons-round mr-10-px">location_on</i>
                        <span
                          >{{
                            akquiseDatenStore.akquiseDaten.lieferadresse.strasse
                          }}
                          {{
                            akquiseDatenStore.akquiseDaten.lieferadresse.hausNr
                          }},
                          {{ akquiseDatenStore.akquiseDaten.lieferadresse.plz }}
                          {{
                            akquiseDatenStore.akquiseDaten.lieferadresse.ort
                          }}</span
                        >
                      </div>
                      <div
                        class="flex-row-together mb-1"
                        v-if="
                          akquiseDatenStore.akquiseDaten
                            .abweichendeRechnungsadresse
                        "
                      >
                        <i class="material-icons-round mr-10-px"
                          >receipt_long</i
                        >
                        <span
                          >{{
                            akquiseDatenStore.akquiseDaten.rechnungsadresse
                              .strasse
                          }}
                          {{
                            akquiseDatenStore.akquiseDaten.rechnungsadresse
                              .hausNr
                          }},
                          {{
                            akquiseDatenStore.akquiseDaten.rechnungsadresse.plz
                          }}
                          {{
                            akquiseDatenStore.akquiseDaten.rechnungsadresse.ort
                          }}</span
                        >
                      </div>
                      <div class="flex-row-together mb-1">
                        <i class="material-icons-round mr-10-px">pin</i>
                        <span>{{
                          akquiseDatenStore.akquiseDaten.zaehlerNummer
                        }}</span>
                      </div>
                      <div class="flex-row-together gap-45">
                        <div class="flex-row-together">
                          <i class="material-icons-round mr-10-px">mail</i>
                          <span>{{
                            akquiseDatenStore.akquiseDaten.email
                          }}</span>
                        </div>
                        <div
                          class="flex-row-together"
                          v-if="akquiseDatenStore.akquiseDaten.telefonnummer"
                        >
                          <i class="material-icons-round mr-10-px">call</i>
                          <span>{{
                            akquiseDatenStore.akquiseDaten.telefonnummer
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="small-bold mb-1">Tarif und Zahlung</div>
                    <div class="content-box-content content-box-text-bold-grey">
                      <div class="flex-row-together mb-1">
                        <i class="material-icons-round mr-10-px">bolt</i>
                        <div class="flex-column non-aligned "><span
                        >{{ sparteLabel }} </span
                        >
                          <span class="content-box-text-regular">{{
                              akquiseDatenStore.akquiseDaten.verbrauch
                                  ?.toString()
                                  .replace(".", ",")
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            }}
                    kWh im Jahr</span>
                        </div>
                      </div>
                      <div class="flex-row-together mb-1">
                        <i class="material-icons-round mr-10-px">euro_symbol</i>
                        <div class="flex-column non-aligned"
                        ><span>{{ euroValueFormatter(currentTarif.price) }}
                      {{
                            currentTarif.garantie == "1 Monat"
                                ? "pro Monat"
                                : "pro Monat"
                          }}</span>

                        <span
                            class="content-box-text-regular">Arbeitspreis: {{
                            centValueFormatter(currentTarif.verbrauchspreisTotal)
                          }}/kWh, Grundpreis: {{ euroValueFormatter(currentTarif.grundpreisTotal) }}/Monat</span>
                      </div></div
                    >
                      <div class="flex-row-together mb-1">
                        <i class="material-icons-round mr-10-px">event</i>
                        <div class="flex-column non-aligned"> <span>{{
                            akquiseDatenStore.akquiseDaten.asFastAsPossible
                                ? "Schnellstmöglicher Lieferbeginn"
                                : "Lieferung zu Wunschdatum"
                          }}</span>
                          <span class="content-box-text-regular" v-if="!akquiseDatenStore.akquiseDaten.asFastAsPossible">
{{
                              akquiseDatenStore.akquiseDaten.wechsel
                                  ? "Lieferbeginn am " + formatDate(
                                  akquiseDatenStore.akquiseDaten.wechseltermin
                              )
                                  : "Lieferbeginn am " + formatDate(
                                  akquiseDatenStore.akquiseDaten.umzugstermin
                              )
                            }}
                  </span></div>
                      </div>
                      <div
                        class="flex-row-together"
                        :class="
                          akquiseDatenStore.akquiseDaten.aktionscode
                            ? 'mb-1'
                            : ''
                        "
                      >
                        <i class="material-icons-round mr-10-px">info</i>
                        <div class="flex-column non-aligned"><span
                        >Zahlung per
                    {{
                            akquiseDatenStore.akquiseDaten.lastschrift
                                ? "Lastschrift"
                                : "Überweisung"
                          }}
                  </span>
                          <span v-if="akquiseDatenStore.akquiseDaten.lastschrift" class="content-box-text-regular">IBAN endet mit {{
                              akquiseDatenStore.akquiseDaten.iban
                                  ? akquiseDatenStore.akquiseDaten.iban?.replaceAll(" ","").slice(-4)
                                  : ""
                            }}</span></div>
                      </div>
                      <div
                        class="flex-row-together"
                        v-if="akquiseDatenStore.akquiseDaten.aktionscode"
                      >
                        <i class="material-icons-round mr-10-px"
                          >confirmation_number</i
                        >
                        <span
                          >{{ akquiseDatenStore.akquiseDaten.aktionscode }} ({{
                            akquiseDatenStore.akquiseDaten
                              .aktionscodeSuccessMeldung
                          }})</span
                        >
                      </div>
                    </div>
                  </div>
                  <JouleButton
                    class="p-button-secondary"
                    outlined
                    label="Speichern"
                    @click="generatePdf"
                  ></JouleButton>
                </div>
              </template>
            </content-box>
          </div>
        </template>
      </Card>
    </div>
    <div
      class="card-wrapper"
      v-if="currentTarif.tarifTypEnum === ITarifTypEnum.BASE"
    >
      <Card class="card">
        <template #title>
          <div class="card-headline-small">Ihr neues Kundenportal</div>
        </template>
        <template #content>
          <div class="mb-2">
            Freuen Sie sich auf nützliche Funktionen und Informationen rund um
            Ihren neuen Stromtarif von GP JOULE! Den Zugang zu Ihrem
            Kundenportal erhalten Sie schon bald per E-Mail.
          </div>
          <div class="mb-2">
            <b>Strompreise immer zur Hand:</b> Behalten Sie die stündlichen
            Strompreise nun noch einfacher im Blick und legen Ihren Verbrauch in
            besonders günstige Zeiten.
          </div>
          <img class="mb-2" src="@/assets/gpj_kundenportal-chart-green_energy.png" />
          <div class="mb-2">
            <b>Transparenz über Ihren Verbrauch:</b> Wenn bei Ihnen ein Smart Meter von einem unserer Partner in Betrieb ist, können Sie Ihren stündlichen Stromverbrauch verfolgen.
          </div>
          <img src="@/assets/gpj_kundenportal-chart-consumption.png" />
        </template>
      </Card>
    </div>
    <div
      class="card-wrapper"
      v-if="currentTarif.tarifTypEnum === ITarifTypEnum.FLEX"
    >
      <Card class="card">
        <template #title>
          <div class="card-headline-small">Ihr neues Kundenportal</div>
        </template>
        <template #content>
          <div class="mb-2">
            Freuen Sie sich auf nützliche Funktionen und Informationen rund um
            Ihren neuen Stromtarif von GP JOULE! Den Zugang zu Ihrem
            Kundenportal erhalten Sie schon bald per E-Mail.
          </div>
          <div class="mb-2">
            <b>Noch nachhaltiger Strom verbrauchen:</b> Sehen Sie auf einen
            Blick, wann viel erneuerbare Energie aus Sonne und Wind im Stromnetz
            vorhanden ist. Der Verbrauch in diesen Zeiten ist besonders
            nachhaltig.
          </div>
          <img
            class="mb-2"
            src="@/assets/gpj_kundenportal-chart-price.png"
          />
          <div class="mb-2">
            <b>Transparenz über Ihren Verbrauch:</b> Wenn bei Ihnen ein Smart Meter von einem unserer Partner in Betrieb ist, können Sie Ihren stündlichen Stromverbrauch verfolgen.
          </div>
          <img src="@/assets/gpj_kundenportal-chart-consumption.png" />
        </template>
      </Card>
    </div>

    <!-- PDF Content -->
    <div class="card-wrapper" style="display: none" id="pdfId">
      <Card class="card">
        <template #title>
          <div class="card-headline">Vielen Dank für Ihre Bestellung!</div>
        </template>
        <template #content>
          <div class="mb-2">
          <content-box>
            <template #content>
              <div class="subline-text">
                Ihr Wechsel wird nun von uns vorbereitet. Sie erhalten in der
                Zwischenzeit eine E-Mail mit der Zusammenfassung Ihrer
                Bestellung mit allen von Ihnen gemachten Angaben an folgende
                E-Mail-Adresse:
              </div>
              <div class="flex-row-centered content-box-text-bold-grey">
                <div class="material-icons-round">mail</div>
                <div>{{ akquiseDatenStore.akquiseDaten.email }}</div>
              </div>
            </template>
          </content-box>
          </div>
          <content-box>
            <template #headline>
              <div class="content-box-text-bold mb-2">Zusammenfassung</div>
            </template>
            <template #content>
              <div class="mb-2">
                <div class="content-box-text-bold mb-1">Vertragsdaten</div>
                <div class="content-box-content content-box-text-bold-grey">
                  <div class="flex-row-together gap-45 mb-1">
                    <div
                      class="flex-row-together"
                      v-if="akquiseDatenStore.akquiseDaten.firma"
                    >
                      <i class="material-icons-round mr-10-px">factory</i>
                      <span>{{
                        akquiseDatenStore.akquiseDaten.lieferadresse.firma
                      }}</span>
                    </div>
                  </div>

                  <div
                    class="flex-row-together mb-1"
                    v-if="akquiseDatenStore.akquiseDaten.lieferadresse.nachname"
                  >
                    <i class="material-icons-round mr-10-px">person</i>
                    <span
                      >{{
                        akquiseDatenStore.akquiseDaten.lieferadresse.vorname
                      }}
                      {{
                        akquiseDatenStore.akquiseDaten.lieferadresse.nachname
                      }}</span
                    >
                  </div>
                  <div
                        class="flex-row-together mb-1"
                        v-if="
                          akquiseDatenStore.akquiseDaten.geburtsdatum
                        "
                      >
                        <i class="material-icons-round mr-10-px">event</i>
                        <span
                          >{{
                            formatDate(akquiseDatenStore.akquiseDaten.geburtsdatum)
                          }}</span
                        >
                      </div>
                  <div class="flex-row-together mb-1">
                    <i class="material-icons-round mr-10-px">location_on</i>
                    <span
                      >{{
                        akquiseDatenStore.akquiseDaten.lieferadresse.strasse
                      }}
                      {{ akquiseDatenStore.akquiseDaten.lieferadresse.hausNr }},
                      {{ akquiseDatenStore.akquiseDaten.lieferadresse.plz }}
                      {{
                        akquiseDatenStore.akquiseDaten.lieferadresse.ort
                      }}</span
                    >
                  </div>
                  <div
                    class="flex-row-together mb-1"
                    v-if="
                      akquiseDatenStore.akquiseDaten.abweichendeRechnungsadresse
                    "
                  >
                    <i class="material-icons-round mr-10-px">receipt_long</i>
                    <span
                      >{{
                        akquiseDatenStore.akquiseDaten.rechnungsadresse.strasse
                      }}
                      {{
                        akquiseDatenStore.akquiseDaten.rechnungsadresse.hausNr
                      }},
                      {{ akquiseDatenStore.akquiseDaten.rechnungsadresse.plz }}
                      {{
                        akquiseDatenStore.akquiseDaten.rechnungsadresse.ort
                      }}</span
                    >
                  </div>
                  <div class="flex-row-together mb-1">
                    <i class="material-icons-round mr-10-px">pin</i>
                    <span>{{
                      akquiseDatenStore.akquiseDaten.zaehlerNummer
                    }}</span>
                  </div>
                  <div class="flex-row-together gap-45">
                    <div class="flex-row-together">
                      <i class="material-icons-round mr-10-px">mail</i>
                      <span>{{ akquiseDatenStore.akquiseDaten.email }}</span>
                    </div>
                    <div
                      class="flex-row-together"
                      v-if="akquiseDatenStore.akquiseDaten.telefonnummer"
                    >
                      <i class="material-icons-round mr-10-px">call</i>
                      <span>{{
                        akquiseDatenStore.akquiseDaten.telefonnummer
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-2">
                <div class="content-box-text-bold mb-1">Tarif und Zahlung</div>
                <div class="content-box-content content-box-text-bold-grey">
                  <div class="flex-row-together mb-1">
                    <i class="material-icons-round mr-10-px">bolt</i>
                    <div class="flex-column non-aligned "><span
                    >{{ sparteLabel }} </span
                    >
                      <span class="content-box-text-regular">{{
                          akquiseDatenStore.akquiseDaten.verbrauch
                              ?.toString()
                              .replace(".", ",")
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }}
                    kWh im Jahr</span>
                    </div>
                  </div>
                  <div class="flex-row-together mb-1">
                    <i class="material-icons-round mr-10-px">euro_symbol</i>
                    <div class="flex-column non-aligned"
                    ><span>{{ euroValueFormatter(currentTarif.price) }}
                      {{
                        currentTarif.garantie == "1 Monat"
                            ? "pro Monat"
                            : "pro Monat"
                      }}</span>

                      <span
                          class="content-box-text-regular">Arbeitspreis: {{
                          centValueFormatter(currentTarif.verbrauchspreisTotal)
                        }}/kWh, Grundpreis: {{ euroValueFormatter(currentTarif.grundpreisTotal) }}/Monat</span>
                    </div></div
                  >
                  <div class="flex-row-together mb-1">
                    <i class="material-icons-round mr-10-px">event</i>
                    <div class="flex-column non-aligned"> <span>{{
                        akquiseDatenStore.akquiseDaten.asFastAsPossible
                            ? "Schnellstmöglicher Lieferbeginn"
                            : "Lieferung zu Wunschdatum"
                      }}</span>
                      <span class="content-box-text-regular" v-if="!akquiseDatenStore.akquiseDaten.asFastAsPossible">
{{
                          akquiseDatenStore.akquiseDaten.wechsel
                              ? "Lieferbeginn am " + formatDate(
                              akquiseDatenStore.akquiseDaten.wechseltermin
                          )
                              : "Lieferbeginn am " + formatDate(
                              akquiseDatenStore.akquiseDaten.umzugstermin
                          )
                        }}
                  </span></div>
                  </div>
                  <div
                      class="flex-row-together"
                      :class="
                          akquiseDatenStore.akquiseDaten.aktionscode
                            ? 'mb-1'
                            : ''
                        "
                  >
                    <i class="material-icons-round mr-10-px">info</i>
                    <div class="flex-column non-aligned"><span
                    >Zahlung per
                    {{
                        akquiseDatenStore.akquiseDaten.lastschrift
                            ? "Lastschrift"
                            : "Überweisung"
                      }}
                  </span>
                      <span v-if="akquiseDatenStore.akquiseDaten.lastschrift" class="content-box-text-regular">IBAN endet mit {{
                          akquiseDatenStore.akquiseDaten.iban
                              ? akquiseDatenStore.akquiseDaten.iban?.replaceAll(" ","").slice(-4)
                              : ""
                        }}</span></div>
                  </div>
                  <div
                    class="flex-row-together"
                    v-if="akquiseDatenStore.akquiseDaten.aktionscode"
                  >
                    <i class="material-icons-round mr-10-px"
                      >confirmation_number</i
                    >
                    <span
                      >{{ akquiseDatenStore.akquiseDaten.aktionscode }} ({{
                        akquiseDatenStore.akquiseDaten
                          .aktionscodeSuccessMeldung
                      }})</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </content-box>
        </template>
      </Card>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.card-wrapper {
  color: grey;
}

.toggle-panel-header {
  width: 100%;
  cursor: pointer;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
</style>

export enum SparteEnum {
    STROM = "STROM",
    WAERMEPUMPE = "WAERMEPUMPE",
    NACHTSPEICHERHEIZUNG = "NACHTSPEICHERHEIZUNG",
    AUTOSTROM = "AUTOSTROM"
}

export const getLabelBySparteEnum = (sparte: SparteEnum): string => {
    if (!sparte) {
        return "";
    } else if (sparte === SparteEnum.STROM) {
        return "Strom";
    } else if (sparte === SparteEnum.WAERMEPUMPE || sparte === SparteEnum.NACHTSPEICHERHEIZUNG) {
        return "Wärmestrom";
    } else if (sparte === SparteEnum.AUTOSTROM) {
        return "Autostrom";
    } else {
        return "";
    }
}

<script setup lang="ts">
import {useStore} from "@/store/AkquiseDatenStore";
import JouleProgressSteps from "@/components/JouleProgressSteps.vue";
import router from "@/router";
import JouleFooter from "@/components/JouleFooter.vue";
import JouleLogo from "@/components/JouleLogo.vue";
import {AkquiseStepEnum} from "@/types/AkquiseStepEnum";

let akquiseDatenStore = useStore();
// initially load data
akquiseDatenStore.getAkquiseDaten();

const dafaultTitle = 'Jetzt zu GP JOULE wechseln';
router.afterEach((to) => {
  document.title = to.meta.title as string || dafaultTitle;
});
</script>

<template>
  <div class="logo-wrapper">
    <img class="logo bottom" src="@/assets/Vector-1.png" alt="ss">
    <img class="logo top" src="@/assets/Vector.png" alt="ss">
  </div>

  <JouleProgressSteps :step="$route.meta.step"></JouleProgressSteps>

  <router-view
      v-slot="{ Component }"
      :key="$route.path"
      class="content mt-90-px"
  >
    <component :is="Component"/>
  </router-view>
  <JouleFooter class="content"></JouleFooter>
  <JouleLogo v-if="$route.meta.step == AkquiseStepEnum.TARIF_AUSWAHL || $route.meta.step == AkquiseStepEnum.VERBRAUCH"></JouleLogo>
</template>
<style lang="scss">
@import "assets/scss/style";
#app {
  font-family: Avenir Next, serif;
  width:100%;
}

body {
  background-color: #EFEFEF;
  display:flex;
  justify-content:center;
  align-items:center;
  margin:0;
}

.content {
  position: relative;
  z-index: 2;
}

.logo-wrapper {
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
}

@media all and (min-width: 575px) {
  .logo-wrapper {
    gap: 50px;
  }
  .logo {
    width: 136px;
  }
}

@media all and (max-width: 575px) {
  .logo-wrapper {
    gap: 25px;
  }
  .logo {
    width: 87px;
  }
}
</style>

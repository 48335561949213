<script setup lang="ts">
import {defineProps, PropType, ref} from "vue";

const props = defineProps({
  text: {
    type: String
  },
  variant: {
    type: String as PropType<"normal" | "light">,
    default: () => "normal"
  },
  size: {
    type: String as PropType<"normal" | "large" | "full">,
    default: () => "normal"
  }
})

const tooltip = ref<HTMLInputElement | null>(null);
const popup = ref<HTMLInputElement | null>(null)
const showPopup = ref<boolean>(false)
const marginLeft = ref('')
let lastTooltipTrigger = new Date();
const handlePlacementHoverIn = () => {
  handlePlacement(true)
}
const handlePlacementHoverOut = () => {
  handlePlacement(false)
}
const handlePlacementClick = () => {
  handlePlacement(null)
}

const handlePlacement = (enter: boolean | null) => {
  if (new Date() - lastTooltipTrigger < 100) {
    return;
  }
  lastTooltipTrigger = new Date();
  let leftSpace = tooltip.value.getBoundingClientRect().left;
  let innerWidth = window.innerWidth;

  let topSpace = tooltip.value.getBoundingClientRect().top;
  let innerHeight = window.innerHeight;

  if (enter === null) {
    showPopup.value = !showPopup.value;
  } else {
    showPopup.value = enter;
  }
  if (marginLeft.value === '') {
    let styleValue = '';
    // we need a timeout here to get the correct width and height of the popup because there is a race condition after showPopup.value being set to true
    // the tooltip is not yet rendered and therefore the width and height are still 0
    setTimeout(function () {
      let itemWidth = popup.value.offsetWidth;
      if (innerWidth < (leftSpace + itemWidth + (tooltip.value.offsetWidth+25))) {
        if ((leftSpace - 10) < itemWidth) {
          styleValue = 'margin-left: -' + (leftSpace) + 'px; width: ' + (leftSpace - 10) + 'px;';
        } else {
          styleValue = 'margin-left: -' + (10 + itemWidth) + 'px; width: ' + itemWidth + 'px;';
        }
      } else {
        styleValue = 'margin-left: '+(tooltip.value.offsetWidth+10)+'px;';
      }

      let itemHeight = popup.value.offsetHeight;
      if (innerHeight < (topSpace + itemHeight + 25)) {
        styleValue = styleValue + 'margin-top: -' + (itemHeight) + 'px;';
      } else {
        styleValue = styleValue + 'margin-top: -'+ (tooltip.value.offsetHeight)+'px;';
      }
      marginLeft.value = styleValue;
    }, 0);
  }
}

window.addEventListener("mouseup", e => {
// close tooltip on click outside
  if (showPopup.value) {
    handlePlacement(false);
  }
})
window.addEventListener('resize', () => {
  // marginLeft will be repositioned on next opening of a tooltip
  marginLeft.value = '';
})

</script>

<template>
  <div class="tooltip-icon tooltip-font" ref="tooltip" :class="[variant === 'light' ? 'tooltip-light' : '']">
    <div class="tooltip-icon tooltip-font"
         @click="handlePlacementClick()"
         @mouseenter="handlePlacementHoverIn()"
         @mouseleave="handlePlacementHoverOut()"
         :class="[variant === 'light' ? 'tooltip-light' : '', size === 'large' ? 'tooltip-icon-large': (size === 'full' ? 'tooltip-icon-full' : '')]">
      <slot>
        <span>i</span>
      </slot>
      <div class="tooltip-overlay" v-show="showPopup">
        <div class="tooltip-text" ref="popup" :style="marginLeft" :class="variant === 'light' ? 'tooltip-light' : ''"
             v-html="text">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tooltip-font {
  color: var(--GPJ-Black, var(--GPJ-Black, #000));
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 210% */
}

.tooltip-font.tooltip-icon-large {
  font-size: 18px;
}

.tooltip-icon.tooltip-icon-full {
  height: 100%;
  width: 100%;
}

.tooltip-icon.tooltip-icon-large {
  height: 24px;
  width: 24px;
}

.tooltip-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  margin-bottom: 2px;
  background: var(--black-07);
}

.tooltip-icon.tooltip-light {
  background: var(--white);
}

.tooltip-overlay {
  display: flex;
}

.tooltip-text {
  display: flex;
  background: var(--grey);
  margin-left: 25px;
  margin-top: -20px;
  max-width: 250px;
  padding: 6px 14px 6px 14px;
  border-radius: 8px;
  z-index: 2;
  color: var(--GPJ-White, var(--GPJ-White, #FFF));
  font-size: 12px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tooltip-text.tooltip-light {
  background: var(--grey);
}

</style>

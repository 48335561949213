<script setup lang="ts">

import JouleSelectButton from "@/components/JouleSelectButton.vue";
import {useStore} from "@/store/AkquiseDatenStore";

const options = [{label: "Geschäftskunde", value: true, icon: "factory"}, {
  label: "Privatkunde",
  value: false,
  icon: "person"
}]
const akquiseDatenStore = useStore();
</script>

<template>
  <div class="mb-2">
    <JouleSelectButton v-model="akquiseDatenStore.akquiseDaten.firma" :options="options"
                       label="Sind Sie Geschäfts- oder Privatkunde?" @changed="$emit('buttonChange')">
    </JouleSelectButton>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

</style>
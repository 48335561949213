import { createVNode, render } from 'vue';
import ChartTooltip from '../components/ChartTooltip.vue';
import { Chart, ChartTypeRegistry, Point, BubbleDataPoint, TooltipModel } from 'chart.js';

export const generateTooltip = (
  ctx: {
    chart: Chart<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[], unknown>;
    tooltip: TooltipModel<keyof ChartTypeRegistry>;
  },
  labels: string[],
  bottomLabel?: string,
) => {
  const { chart, tooltip } = ctx;
  if (!chart.canvas.parentNode) return;
  let el = chart.canvas.parentNode.querySelector('div');
  if (!el) {
    el = chart.canvas.parentNode.appendChild(document.createElement('div'));
    el.style.opacity = '1';
    el.style.pointerEvents = 'none';
    el.style.position = 'absolute';
    el.style.transform = 'translate(-50%, 0)';
    el.style.transition = 'all .2s ease';
  }

  if (tooltip.opacity === 0) {
    el.style.opacity = '0';
    return;
  }

  const vNode = createVNode(ChartTooltip, {
    heading: tooltip.title[0],
    labels: labels,
    values: tooltip.body[0].lines,
    bottomLabel: bottomLabel,
    bottomValue: tooltip.footer[0],
  });
  render(vNode, el);

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  el.style.opacity = '1';
  el.style.left = positionX + tooltip.caretX + 'px';
  el.style.top = positionY + tooltip.caretY + 'px';
  el.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

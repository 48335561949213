<script setup lang="ts">
import {defineEmits, defineProps, ref, watch} from "vue";
import {defineModel} from "vue/dist/vue";
import dayjs from 'dayjs';

const props = defineProps({
  label: {
    type: String,
    default: () => ""
  },
  icon: {
    type: String,
    default: () => ""
  },
  min: {
    type: String,
    default: () => null
  },
  max: {
    type: String,
    default: () => null
  },
  errorText: {
    type: String,
    default: () => ''
  },
  // If inputValid is null, the input field will be shown as valid if model
  // is not empty and errorText is empty
  inputValid: {
    type: Boolean,
    default: () => null
  }
})
const model = defineModel();
const emits = defineEmits(['inputChanged']);

const calendarModel = ref(model.value ? dayjs(model.value, "YYYY-MM-DD").toDate() : null);

watch(model, (newModel) => {
  if (!isNaN(calendarModel.value) && newModel && dayjs(calendarModel.value, "DD.MM.YYYY").format("YYYY-MM-DD") !== model.value) {
    calendarModel.value = dayjs(newModel, "YYYY-MM-DD").toDate();
  }
})

watch(calendarModel, (newModel) => {
  if (calendarModel.value == null) {
    model.value = null;
    emits('inputChanged');

  } else if (!isNaN(calendarModel.value) && dayjs(calendarModel.value, "DD.MM.YYYY").format("YYYY-MM-DD") !== model.value) {
    model.value = dayjs(newModel).format("YYYY-MM-DD");
    emits('inputChanged');
  }
});

const formatInput = (e) => {
  // method to format the input to DD.MM.YYYY if user just types in DDMMYYYY
  if (e.target.value.length >= 3 && e.target.value.charAt(2) !== '.') {
    e.target.value = e.target.value.slice(0, 2) + '.' + e.target.value.slice(2);
  }
  if (e.target.value.length >= 6 && e.target.value.charAt(5) !== '.') {
    e.target.value = e.target.value.slice(0, 5) + '.' + e.target.value.slice(5);
  }

};

</script>

<template>
  <div class="input-field-wrapper">
    <div class="input-text-header">
      {{ props.label }}
    </div>
    <SignupCalendar placeholder="TT.MM.JJJJ" class="w-full p-input-icon-left"
                    :class="errorText != '' && errorText!= null ? 'error':''" v-model="calendarModel"
                    icon="location_on" @input="formatInput" showIcon iconDisplay="input" dateFormat="dd.mm.yy"
                    :min-date="dayjs(min, 'YYYY-MM-DD').toDate()" :max-date="dayjs(max, 'YYYY-MM-DD').toDate()">
      <template #inputicon="{ clickCallback }">
        <i class="material-icons-round" @click="clickCallback">{{ icon }}</i>
        <div class="p-input-icon-right"
             v-if="inputValid == null ? (errorText == '' && model != '' && model != null) : inputValid">
          <i class="material-icons-round">done</i>
        </div>
      </template>
    </SignupCalendar>
    <div v-if="errorText!=''" class="error-text-wrapper">
      <span class="material-icons-round error-text mr-5-px">info</span>
      <div class="error-text">{{ errorText }}</div>
    </div>  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

</style>

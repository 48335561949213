export enum AkquiseStepEnum {
    VERBRAUCH = 'VERBRAUCH',
    TARIF_AUSWAHL = 'TARIF_AUSWAHL',
    KONTAKTDATEN = 'KONTAKTDATEN',
    ADRESSE = 'ADRESSE',
    LIEFERBEGINN = 'LIEFERBEGINN',
    ZAEHLER = 'ZAEHLER',
    ZAHLUNG = 'ZAHLUNG',
    EMAIL_VERIFIZIEREN = 'EMAIL_VERIFIZIEREN',
    ZUSAMMENFASSUNG = 'ZUSAMMENFASSUNG',
    BESTAETIGUNG = 'BESTAETIGUNG',
    NONE = 'NONE',
}

export const getIndexByStep = (step: AkquiseStepEnum): number => {
    switch (step) {
        case AkquiseStepEnum.VERBRAUCH:
            return 1;
        case AkquiseStepEnum.TARIF_AUSWAHL:
            return 2;
        case AkquiseStepEnum.KONTAKTDATEN:
            return 3;
        case AkquiseStepEnum.ADRESSE:
            return 4;
        case AkquiseStepEnum.LIEFERBEGINN:
            return 5;
        case AkquiseStepEnum.ZAEHLER:
            return 6;
        case AkquiseStepEnum.ZAHLUNG:
            return 7;
        case AkquiseStepEnum.EMAIL_VERIFIZIEREN:
            return 8;
        case AkquiseStepEnum.ZUSAMMENFASSUNG:
            return 9;
        case AkquiseStepEnum.BESTAETIGUNG:
            return 10;
        default:
            return 1;
    }
}

export const getRouteNameByStep = (step: AkquiseStepEnum): string => {
    switch (step) {
        case AkquiseStepEnum.VERBRAUCH:
            return "Tarifrechner";
        case AkquiseStepEnum.TARIF_AUSWAHL:
            return "TarifrechnerDetail";
        case AkquiseStepEnum.KONTAKTDATEN:
            return "Kontaktdaten";
        case AkquiseStepEnum.ADRESSE:
            return "Lieferadresse";
        case AkquiseStepEnum.LIEFERBEGINN:
            return "Wechsel";
        case AkquiseStepEnum.ZAHLUNG:
            return "Zahlungsdetails";
        case AkquiseStepEnum.ZAEHLER:
            return "Zaehler";
        case AkquiseStepEnum.EMAIL_VERIFIZIEREN:
            return "EmailVerifizierung";
        case AkquiseStepEnum.ZUSAMMENFASSUNG:
            return "Zusammenfassung";
        case AkquiseStepEnum.BESTAETIGUNG:
            return "Bestellbestaetigung";
        default:
            return "Tarifrechner";
    }
}

<script setup lang="ts">
import {defineProps, PropType, ref, watch} from "vue";
import JouleTimer from "@/components/JouleTimer.vue";
import {defineEmits} from "vue/dist/vue";

const emits = defineEmits(['click'])

const props = defineProps({
  label: {
    type: String,
    default: () => ""
  },
  icon: {
    type: String,
    default: () => ""
  },
  alignment: {
    type: String as PropType<"space-between" | "center">,
    default: () => "center"
  },
  timer: {
    type: Number,
    default: () => 90
  },
  disabled:{
    type:Boolean,
    default:true
  }
})
const enabled = ref(false);

const onClick = () => {
  enabled.value = false;
  emits('click');
}

</script>

<template>
  <SignupButton :disabled="!enabled"
                class="joule-timer-button"
                @click="onClick"
                :class="alignment === 'space-between' ? 'joule-button-space-between' : 'joule-button'">
    <div class="button-content-container">
      <div class="button-content-item"><JouleTimer v-if="!disabled" v-model="enabled" :timer="timer" class="mr-1"></JouleTimer></div>
      <div class="button-content-item"><span class="mt-4-px">{{ label }}</span></div>
      <div class="button-content-item"><i class="material-icons-round">{{ icon }}</i></div>
    </div>
  </SignupButton>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";
.button-content-container {
  display: flex;
  width: 100%;
}
.button-content-item {
  flex: 1;
  display: flex;
  justify-content: center;
}

.button-content-item:first-child {
  justify-content: end;
}

.button-content-item:nth-child(2) {
  white-space: nowrap;
}
.button-content-item:last-child {
  justify-content: start;
}
.button-content-item {
  align-items: center;
}
p {
  text-align: center;
}


.joule-button {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.joule-button-space-between {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.joule-timer-button:disabled {
  opacity: 0.5 !important;
  background: var(--yellow) !important;
}
</style>

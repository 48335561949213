import {IPlzOrt} from "@/types/PlzOrt";

export const getPlzOrt = async (searchTerm: string): Promise<IPlzOrt[]> => {
    return fetch("/api/plz-ort?searchTerm=" + searchTerm, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return [];
        }
    }, () => {
        return [];
    });
};

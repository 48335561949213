<script setup lang="ts">
import JouleInputField from "@/components/JouleInputField.vue";
import Card from "primevue/card";
import {useStore} from "@/store/AkquiseDatenStore";
import {useStore as useValStore} from "@/store/StepValidationStore";
import JouleButton from "@/components/JouleButton.vue";
import router from "@/router";
import {handleJentis} from "@/utils/JentisService";
import {onMounted, ref} from "vue";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)
handleJentis();

let akquiseDatenStore = useStore();
const stepValidationStore = useValStore();
const next = async () => {
  setCorrectDate();
  await akquiseDatenStore.postAkquiseDaten();
  await router.push({name: "Lieferadresse"});
}
const back = async () => {
  await akquiseDatenStore.postAkquiseDaten();
  router.push({name: "TarifrechnerDetail"});
}
const validate = async () => {
  akquiseDatenStore.akquiseDaten.geburtsdatum = dayjs(birthDate.value, 'DD.MM.YYYY').format('YYYY-MM-DD') !== "Invalid Date" ? dayjs(birthDate.value, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
  await akquiseDatenStore.postAkquiseDaten();
  await stepValidationStore.validate();
}

const setCorrectDate = () => {
  if (!isValidDate(akquiseDatenStore.akquiseDaten.geburtsdatum)) {
    akquiseDatenStore.akquiseDaten.geburtsdatum = null;
  }
  if (akquiseDatenStore.akquiseDaten.firma) {
    akquiseDatenStore.akquiseDaten.geburtsdatum = null;
  }
}

const isValidDate = (dateString) => {
  if (!dateString) return false;
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false;  // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
};

const birthDate = ref("");

const formatInput = (event: InputEvent) => {
  if (birthDate.value.length > 10) {
    birthDate.value = birthDate.value.slice(0, 10);
  }
  if (
      birthDate.value.length === 3 &&
      event.inputType === "insertText" &&
      event.data !== "."
  ) {
    birthDate.value =
        birthDate.value.slice(0, 2) + "." + birthDate.value.at(2);
  } else if (
      birthDate.value.length === 6 &&
      event.inputType === "insertText" &&
      event.data !== "."
  ) {
    birthDate.value =
        birthDate.value.slice(0, 5) + "." + birthDate.value.at(5);
  }

};

onMounted(() => {
  akquiseDatenStore.akquiseDaten.geburtsdatum ? birthDate.value = dayjs(akquiseDatenStore.akquiseDaten.geburtsdatum).format("DD.MM.YYYY") : {}
})
</script>

<template>
  <div class="card-wrapper">
    <Card class="card">
      <template #title>
        <div class="card-headline-small">Ihre Kontaktdaten</div>

      </template>
      <template #content>
        <div class="flex-row mb-2 non-aligned name-row">
          <JouleInputField v-model="akquiseDatenStore.akquiseDaten.lieferadresse.vorname" @enter="next"
                           :error-text="stepValidationStore.getErrorByFieldName('lieferadresse.vorname')"
                           label="Vorname" placeholder="Vornamen eingeben" @input-changed="validate">
          </JouleInputField>
          <JouleInputField v-model="akquiseDatenStore.akquiseDaten.lieferadresse.nachname"
                           :error-text="stepValidationStore.getErrorByFieldName('lieferadresse.nachname')"
                           label="Nachname" placeholder="Nachnamen eingeben" @input-changed="validate" @enter="next">
          </JouleInputField>
        </div>
        <JouleInputField v-if="akquiseDatenStore.akquiseDaten.firma"
                         v-model="akquiseDatenStore.akquiseDaten.lieferadresse.firma"
                         class="mb-2"
                         :error-text="stepValidationStore.getErrorByFieldName('lieferadresse.firma')"
                         label="Firma" placeholder="Muster GmbH" @input-changed="validate" @enter="next">
        </JouleInputField>

        <JouleInputField v-else
                         v-model="birthDate"
                         class="mb-2" icon="calendar_month"
                         @input="formatInput"
                         :input-valid="stepValidationStore.akquiuseDatenValidation.geburtsdatumValid"
                         :error-text="stepValidationStore.getErrorByFieldName('geburtsdatum')"
                         @input-changed="validate" @enter="next"
                         label="Geburtsdatum" placeholder="TT.MM.JJJJ"
        >{{ birthDate }}
        </JouleInputField>
        <JouleInputField v-model="akquiseDatenStore.akquiseDaten.email"
                         class="mb-2"
                         :input-valid="stepValidationStore.akquiuseDatenValidation.emailValid"
                         :error-text="stepValidationStore.getErrorByFieldName('email')"
                         label="E-Mail-Adresse" placeholder="beispiel@muster.de" @input-changed="validate"
                         @enter="next">
        </JouleInputField>
        <JouleInputField optional="true" v-model="akquiseDatenStore.akquiseDaten.telefonnummer"
                         class="mb-2"
                         :error-text="stepValidationStore.getErrorByFieldName('telefonnummer')"
                         label="Telefonnummer" placeholder="+49 123 45678901" @input-changed="validate" @enter="next">
        </JouleInputField>

        <div class="flex-row">
          <JouleButton class="p-button-secondary mr-1 flex-shrink-4 min-w-60" icon="arrow_back"
                       @click="back"></JouleButton>
          <JouleButton label="Weiter" icon="arrow_forward" @click="next"></JouleButton>
        </div>

      </template>
    </Card>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

@media all and (max-width: 576px) {
  .name-row {
    flex-wrap: wrap;
    gap: 2rem;
  }
}

@media all and (min-width: 576px) {
  .name-row {
    gap: 1rem;
  }
}

</style>

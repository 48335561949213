<script setup lang="ts">

</script>

<template>
  <div class="logo">
    <img class="mb-60-px" width="125px" src="@/assets/logo/gpj-logo-black.svg" alt="GP-Joule Logo">
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.logo{
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
}

</style>
<script setup lang="ts">
import JouleInputField from "@/components/JouleInputField.vue";
import Card from "primevue/card";
import ContentBox from "@/components/ContentBox.vue";
import {useStore} from "@/store/AkquiseDatenStore";
import JouleButton from "@/components/JouleButton.vue";
import router from "@/router";
import {useStore as useStepValidationStore} from "@/store/StepValidationStore";
import {useStore as useTarifDatenStore} from "@/store/TarifDatenStore";
import JouleSelectButton from "@/components/JouleSelectButton.vue";
import {handleJentis} from "@/utils/JentisService";
import {PossibleZaehlerEnum} from "@/types/PossibleZaehlerEnum";

handleJentis();

const tarifDatenStore = useTarifDatenStore();
const akquiseDatenStore = useStore();
const stepValidationStore = useStepValidationStore();

if (akquiseDatenStore.akquiseDaten.akquiseSession) {
  tarifDatenStore.hasEtAndZtPreise(akquiseDatenStore.akquiseDaten.akquiseSession);
}

const back = () => {
  router.push({name: "Wechsel"});
};
const next = async () => {
  await akquiseDatenStore.postAkquiseDaten();
  router.push({name: "Zahlungsdetails"});
};
const validate = async () => {
  await akquiseDatenStore.postAkquiseDaten();
  await stepValidationStore.validate();
};

const options = [
  {label: "Eintarifzähler", value: true},
  {
    label: "Zweitarifzähler",
    value: false,
  },
];
</script>

<template>
  <div class="card-wrapper">
    <Card class="card">
      <template #title>
        <div class="card-headline-small">Details zu Ihrem Zähler</div>
      </template>
      <template #content>
        <JouleSelectButton
            v-if="tarifDatenStore.possibleZaehlerEnum === PossibleZaehlerEnum.BOTH"
            label="Zählerart"
            tooltip="So unterscheiden sich die Zählerarten:<br>• Eintarifzähler: Auf dem Zähler wird 1 kWh-Verbrauchsangabe angezeigt. <br>• Zweitarifzähler: Auf dem Zähler werden 2 kWh-Verbrauchsangaben angezeigt. Diese sind meist mit HT und NT (Hoch- und Niedertarif) ausgezeichnet."
            :options="options"
            v-model="akquiseDatenStore.akquiseDaten.eintarifzaehler"
        ></JouleSelectButton>
        <content-box
            class="mt-1"
            v-if="tarifDatenStore.possibleZaehlerEnum === PossibleZaehlerEnum.BOTH && !akquiseDatenStore.akquiseDaten.eintarifzaehler"
        >
          <template #content>
            <span class="content-box-text-regular"
            >Bei einem Zweitarifzähler behalten wir uns Anpassung im Grund-
              und Verbrauchspreis aufgrund veränderter Netznutzungs- und
              Messstellengebühren vor, da die Preiskalkulation standardmäßig auf
              Eintarifzählern basiert.</span
            >
          </template>
        </content-box>
        <JouleInputField
            v-model="akquiseDatenStore.akquiseDaten.zaehlerNummer"
            class="mt-2 mb-2"
            tooltip="Die Zählernummer ist eine einmalige Identifikationsnummer für Ihren Stromzähler. Diese finden Sie direkt auf Ihrem Zähler oder auf früheren Stromabrechnungen."
            :error-text="stepValidationStore.getErrorByFieldName('zaehlerNummer')"
            label="Zählernummer"
            placeholder="Zählernummer eintippen"
            @input-changed="validate"
            @enter="next"
        >
        </JouleInputField>
        <JouleInputField
            v-model="akquiseDatenStore.akquiseDaten.malo"
            class="mb-2"
            :optional="true"
            tooltip="Sie finden die 11-stellige Marktlokations-ID auf früheren Stromrechnungen. Mit dieser ID kann Ihr Wechsel noch schneller durchgeführt werden."
            label="Marktlokations-ID"
            :error-text="stepValidationStore.getErrorByFieldName('malo')"
            placeholder="Marktlokations-ID eintippen"
            @input-changed="validate"
            @enter="next"
        >
        </JouleInputField>
        <div class="flex-row">
          <JouleButton
              class="p-button-secondary mr-1 flex-shrink-4 min-w-60"
              icon="arrow_back"
              @click="back"
          ></JouleButton>
          <JouleButton
              label="Weiter"
              icon="arrow_forward"
              @click="next"
          ></JouleButton>
        </div>
      </template>
    </Card>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.input {
  flex: 1;
}
</style>

<script setup lang="ts">
import JouleInputField from "@/components/JouleInputField.vue";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import {useStore} from "@/store/AkquiseDatenStore";
import JouleButton from "@/components/JouleButton.vue";
import router from "@/router";
import {onMounted, ref} from "vue";
import ContentBox from "@/components/ContentBox.vue";
import JouleSelectButton from "@/components/JouleSelectButton.vue";
import {useStore as useStepValidationStore} from "@/store/StepValidationStore";
import {handleJentis} from "@/utils/JentisService";

handleJentis();

let akquiseDatenStore = useStore();
const stepValidationStore = useStepValidationStore();

const options = [
  {label: "Lastschrift", value: true, icon: "autorenew"},
  {
    label: "Überweisung",
    value: false,
    icon: "receipt_long",
  },
];
const checkBox = ref(false as boolean);
const errorActive = ref(false as boolean);
const next = async () => {
  await akquiseDatenStore.postAkquiseDaten();
  await stepValidationStore.validate();
  if (!checkBox.value && akquiseDatenStore.akquiseDaten.lastschrift) {
    errorActive.value = true;
    stepValidationStore.showErrorsCount = 2;
    return;
  }
  if (akquiseDatenStore.akquiseDaten.emailVerifiziert) {
    router.push({name: "Zusammenfassung"});
  } else {
    await akquiseDatenStore.postAkquiseDaten();
    router.push({name: "EmailVerifizierung"});
  }
};

const back = () => {
  router.push({name: "Zaehler"});
};

const validate = async () => {
  await akquiseDatenStore.postAkquiseDaten();
  await stepValidationStore.validate();
};

onMounted(() => {
  akquiseDatenStore.akquiseDaten.kontoInhaber = akquiseDatenStore.akquiseDaten
      .firma
      ? akquiseDatenStore.akquiseDaten.lieferadresse.firma
      : akquiseDatenStore.akquiseDaten.lieferadresse.vorname +
      " " +
      akquiseDatenStore.akquiseDaten.lieferadresse.nachname;
});
</script>

<template>
  <div class="card-wrapper">
    <Card class="card">
      <template #title>
        <div class="card-headline-small">Details zu Ihrer Zahlung</div>
      </template>
      <template #content>
        <joule-select-button
            class="mb-2"
            :options="options"
            @changed="akquiseDatenStore.postAkquiseDaten"
            v-model="akquiseDatenStore.akquiseDaten.lastschrift"
            label="Lastschrift oder Überweisung?"
        ></joule-select-button>
        <div v-if="akquiseDatenStore.akquiseDaten.lastschrift">
          <JouleInputField
              v-model="akquiseDatenStore.akquiseDaten.kontoInhaber"
              class="mr-1 mb-2"
              :error-text="
              stepValidationStore.getErrorByFieldName('kontoInhaber')
            "
              label="Kontoinhaber"
              placeholder="Max Mustermann"
              @input-changed="validate"
              @enter="next"
          >
          </JouleInputField>
          <JouleInputField
              v-model="akquiseDatenStore.akquiseDaten.iban"
              class="mr-1 mb-2"
              label="IBAN"
              :error-text="stepValidationStore.getErrorByFieldName('iban')"
              placeholder="DE12 3456 7890 1234 5678 00"
              @input-changed="validate"
              @enter="next">
          </JouleInputField>
          <div class="mb-2">
            <div class="flex-row-together non-aligned gap-10">
              <Checkbox
                  inputId="zahlungCheckbox"
                  v-model="checkBox"
                  :class="errorActive ? 'error' : ''"
                  :binary="true"
              ></Checkbox>
              <label for="zahlungCheckbox"
              >Der Kontoinhaber ist mit der Belastung des oben angegebenen Kontos durch die GP JOULE Plus GmbH
                einverstanden.</label
              >
            </div>
            <div v-if="errorActive && !checkBox" class="error-text-wrapper">
              <span class="material-icons-round error-text mr-5-px">info</span>
              <div class="error-text">
                Bitte stimmen Sie den Bedingungen zu
              </div>
            </div>
          </div>

          <div class="mb-2">
            <content-box>
              <template #content>
                <span class="content-box-text-regular"
                >Mit Ihrer Zustimmung ermächtigen Sie die GP JOULE Plus GmbH
                  mit der Gläubiger-Identifikationsnummer DE06 ZZZ00002506923,
                  Zahlungen vom vorgenannten Konto mittels Lastschrift
                  einzuziehen. Zugleich weisen Sie das Kreditinstitut an, die
                  von der GP JOULE Plus GmbH auf o.g. Konto gezogenen
                  Lastschriften einzulösen. Sie können innerhalb von acht
                  Wochen, beginnend mit dem Belastungsdatum, die Erstattung des
                  belasteten Betrages verlangen. Es gelten dabei die mit Ihrem
                  Kreditinstitut vereinbarten Bedingungen.</span
                >
              </template>
            </content-box>
          </div>
        </div>
        <div class="mb-2">
          <content-box v-if="!akquiseDatenStore.akquiseDaten.lastschrift">
            <template #content>
              <span class="content-box-text-regular"
              >Sie erhalten die Zahlungsinformationen gemeinsam mit der
                Vertragsbestätigung per E-Mail.</span
              >
            </template>
          </content-box>
        </div>
        <div class="flex-row">
          <JouleButton
              class="p-button-secondary mr-1 flex-shrink-4 min-w-60"
              icon="arrow_back"
              @click="back"
          >
          </JouleButton>
          <JouleButton label="Weiter" icon="arrow_forward" @click="next">
          </JouleButton>
        </div>
      </template>
    </Card>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";
</style>

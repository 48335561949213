<script setup lang="ts">
import InputText from "primevue/inputtext";
import {defineProps, defineModel, defineEmits} from "vue";
import JouleTooltip from "@/components/JouleTooltip.vue";

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: () => ""
  },
  icon: {
    type: String,
    required: false,
    default: () => ""
  },
  name: {
    type: String,
    default: () => ""
  },
  placeholder: {
    type: String,
    default: () => ""
  },
  disabled: {
    type: Boolean,
    default: () => false
  },
  errorText: {
    type: String,
    default: () => ''
  },
  successText: {
    type: String,
    default: () => ''
  },
  optional: {
    type: Boolean,
    default: () => false
  },
  tooltip: {
    type: String,
    default: () => ""
  },
  // If inputValid is null, the input field will be shown as valid if model
  // is not empty and errorText is empty
  inputValid: {
    type: Boolean,
    default: () => null
  }
})
defineEmits(['inputChanged','enter'])

const model = defineModel();
</script>

<template>
  <div class="input-field-wrapper">
    <div class="input-text-header flex-row-together gap-10">
      <span>{{ label }}</span>
      <JouleTooltip v-if="tooltip != ''" :text="tooltip"></JouleTooltip>
      <span class="optional-text">{{ optional ? 'optional' : '' }}</span>
    </div>
    <div :class="icon ? 'p-input-icon-left' : 'p-input-no-icon'" class="p-input">
      <i class="material-icons-round" :class="errorText != '' ? 'error':''" v-if="icon">{{ props.icon }}</i>
      <InputText @change="$emit('inputChanged')" :name="props.name" :class="errorText != '' ? 'error' : ''" type="text" v-model="model"
                 :placeholder="props.placeholder" :disabled="disabled" @keyup.enter="$emit('enter')"/>
      <icn class="material-icons-round" v-if="inputValid == null ? (errorText == '' && model != '' && model != null) : inputValid">done</icn>
      <div v-if="errorText!=''" class="error-text-wrapper">
        <span class="material-icons-round error-text mr-5-px">info</span>
        <div class="error-text">{{ errorText }}</div>
      </div>
      <div v-if="successText!=''" class="error-text-wrapper">
        <div class="success-text">{{ successText }}</div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.p-input {
  min-height: 40px;
}

.p-input-icon-left {
  width: 100%;
}

.p-input-no-icon {
  width: 100%;
}
</style>

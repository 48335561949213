<script setup lang="ts">

import Button from "primevue/button";
import {computed, defineModel, defineProps, PropType, ref, watch} from "vue";
import {IVerbrauchsVorauswahlOption} from "@/types/VerbrauchVorauswahl";

const props = defineProps({
  buttonHeader: {type: String},
  icon: {type: String},
  data: {type: Array as PropType<IVerbrauchsVorauswahlOption[]>, required: true}
})
const index = ref(0 as number)
const model = defineModel();
const findClosest = () => {
  let closest = props.data[0]
  let minDifference = Math.abs(model.value as number - props.data[0].value);
  for (const item of props.data) {
    const difference = Math.abs(model.value as number - item.value);
    if (difference < minDifference) {
      minDifference = difference;
      closest = item;
    }
  }
  return closest;
}

watch(() => model.value, () => {
  index.value = props.data.indexOf(findClosest());
}, {immediate: true})


const closestVal = computed(() => {
  return findClosest();
})
const increment = () => {
  if (index.value < props.data?.length - 1) {
    index.value = index.value + 1;
    model.value = props.data[index.value].value;
  }
}
const decrement = () => {
  if (index.value > 0) {
    index.value = index.value - 1;
    model.value = props.data[index.value].value;
  }
}
</script>

<template>
  <div class="input-field-wrapper">
    <div class="select-button-header">{{ props.buttonHeader }}</div>
    <div class="increment-input">
      <div>
        <Button class="increment-button" @click="decrement">
          <span class="material-icons-round">remove</span>
        </Button>
      </div>
      <div class="increment-value">
        <span class="material-icons-round">{{ props.icon }}</span>
        <span class="input-kwh">{{ closestVal.label }}</span>
      </div>
      <div>
        <Button class="increment-button">
          <span class="material-icons-round" @click="increment">add</span>
        </Button>

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.select-button-header {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  margin-bottom: 10px;
}

.material-icons-round {
  margin: 0;
  color: var(--GPJ-Grey, #545759);
}

.increment-input {
  border: 2px solid var(--GPJ-Grey, #545759);
  border-radius: 8px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.increment-button {
  padding: 0;
  min-width: 0;
  background: none;
  margin: 15px;
  border-radius: 4px;
}

.increment-button:enabled:focus{
   background:transparent;
 }

.increment-button:enabled:hover{
  background:var(--black-07);
}

.increment-value {
  color: var(--GPJ-Grey, #545759);
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.input-kwh {
  padding-top: 4px;
}
</style>

<script setup lang="ts">
import {defineProps, defineModel} from "vue";
import {defineEmits} from "vue/dist/vue";
import JouleTooltip from "@/components/JouleTooltip.vue";

const props = defineProps({
  label: {
    type: String,
    default: () => ""
  },
  inverted: {
    type: Boolean,
    default: () => false
  },
  inputId: {
    type: String,
    required: true
  },
  tooltip: {
    type: String,
    default:() => ""
  }
});
const model = defineModel()
defineEmits(['inputChanged'])
</script>

<template>
  <div class="flex-row-together non-aligned">
    <InputSwitch :inputId="inputId" v-model="model" class="mr-1" :true-value="!inverted" @change="$emit('inputChanged')" :false-value="inverted"></InputSwitch>
    <div class="flex-row-together gap-10">
    <label :for="inputId">{{ label }}</label>
    <JouleTooltip v-if="tooltip != ''" :text="tooltip"></JouleTooltip>
  </div>
  </div>

</template>

<style scoped lang="scss">

</style>

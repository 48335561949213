<script setup lang="ts">
import {defineProps, PropType, ref, defineModel} from "vue";
import Panel from "primevue/panel";
import {centValueFormatter, euroValueFormatter} from "@/utils/NumberFormatter";
import {ITarif} from "@/types/Tarif";
import ContentBox from "@/components/ContentBox.vue";
import Button from "primevue/button";
import router from "@/router";
import {useStore} from "@/store/AkquiseDatenStore";
import JouleTooltip from "@/components/JouleTooltip.vue";
import {defineEmits} from "vue/dist/vue";
import { useStore as useTarifDatenStore } from "@/store/TarifDatenStore";
import {buttonClicked} from "@/utils/JentisService";

const props = defineProps({
  option: {
    type: Object as PropType<ITarif>
  },
  collapsed: {
    type: Boolean,
    default: true
  }
});

const model = defineModel();
const store = useStore();
const tarifDatenStore = useTarifDatenStore();

const next = async () => {
  await buttonClicked('jetzt_wechseln_clicked');
  store.akquiseDaten.tarifId = props.option?.tarifId as number;
  store.akquiseDaten.eintarifzaehler = props.option?.eintarifZaehler as boolean;
  store.akquiseDaten.grundpreis = props.option?.grundpreisTotal as number;
  store.akquiseDaten.arbeitspreis = props.option?.verbrauchspreisTotal as number;
  await store.postAkquiseDaten();
  // already loading hasEtAndZtPreise so that the store is ready for ZaehlerDetailsView
  if (store.akquiseDaten.akquiseSession) {
    tarifDatenStore.hasEtAndZtPreise(store.akquiseDaten.akquiseSession);
  }
  await router.push({name: "Kontaktdaten"})
}
const preisTooltip = ref("")
defineEmits(['toggleCollapsed'])
</script>

<template>
  <div class="tariff-wrapper">
    <div>
      <div class="card-headline-small mb-1">
        <div class="mb-1">
          {{ option.tarifname }}
        </div>
        <div class="flex-row-together mb-1">
          <span class="card-headline mr-10-px">{{ euroValueFormatter(option.price) }}</span>
          <span class="small-bold mr-10-px">
         {{ option.garantie == '1 Monat' ? 'pro Monat' : 'pro Monat' }}</span>
          <JouleTooltip :text="
          store.akquiseDaten.firma ?'Abschlag basierend auf Ihren Angaben, dem Strompreis (zzgl. Steuern), Abgaben und Beschaffungskosten. Weitere Details sehen Sie in der Preiszusammensetzung.' : 'Abschlag basierend auf Ihren Angaben, dem Strompreis (inkl. Steuern), Abgaben und Beschaffungskosten. Weitere Details sehen Sie in der Preiszusammensetzung.'"></JouleTooltip>
        </div>
      </div>

      <Button class="button mb-2" label="Jetzt wechseln" @click="next"></Button>
      <content-box>
        <template #content>
          <div class="flex-row" v-for="bulletpoint in option.bulletpoints" v-bind:key="bulletpoint">
            <div class="flex-row-together mb-1">
              <i class="material-icons-round small-icon mr-05">check_circle</i>
              <span class="small-semi-bold" :class="bulletpoint.tooltip ? 'mr-1' : ''"
                    v-html="bulletpoint.bulletpoint"></span>
              <JouleTooltip v-if="bulletpoint.tooltip" variant="light" :text="bulletpoint.tooltip"></JouleTooltip>
            </div>
          </div>

          <Panel :collapsed="collapsed">
            <template #header>
              <div class="toggle-panel-header flex-row" @click="$emit('toggleCollapsed')">
                <div class="mt-4-px">Preiszusammensetzung</div>
                <i class="material-icons-round">{{ collapsed ? 'add' : 'remove' }}</i>
              </div>
            </template>
            <template #default>
              <div class="zusammensetzung-wrapper mb-2">
                <div class="zusammensetzung-headline mb-1">Verbrauchspreis <span class="zusammensetzung-headline-small">pro kWh</span>
                </div>
                <div class="flex-row mb-1">
                  <span class="zusammensetzung-bulletpoint-left">
                    {{ option.tarifTypEnum === 'BASE' ? 'Fixierter Strompreis' : 'Fixierter Strompreis im 1. Monat' }}
                  </span>
                  <span class="zusammensetzung-bulletpoint-right">{{
                      centValueFormatter(option.preisErsterMonat)
                    }}</span>
                </div>
                <div class="flex-row mb-1 h-18-px">
                  <div v-if="option.preisAbZweitenMonat" class="zusammensetzung-bulletpoint-left flex-row">
                    <span class="mr-1">Börsenstrompreis ab 2. Monat</span>
                    <JouleTooltip class="mr-1" text="Entspricht dem Börsenstrompreis der EPEX Spot Day-Ahead Auktion. In der Grafik unten sehen Sie die Preise des heutigen Tages." variant="light"></JouleTooltip>
                  </div>
                  <span class="zusammensetzung-bulletpoint-right">{{ option.preisAbZweitenMonat }}</span>
                </div>
                <div class="flex-row mb-1">
                  <div class="zusammensetzung-bulletpoint-left flex-row">
                    <span class="mr-1">Weitere Preisbestandteile</span>
                    <JouleTooltip class="mr-1" text="Insb. Herkunftsnachweise, Netzentgelte, Umlagen, Abgaben, Steuern und sonstige Beschaffungskosten" variant="light"></JouleTooltip>
                  </div>
                  <span class="zusammensetzung-bulletpoint-right">{{
                      centValueFormatter(option.weiterePreisbestandteile)
                    }}</span>
                </div>
                <div class="divider mb-1"></div>
                <div class="flex-row mb-1 zusammensetzung-bulletpoint-right">
                  <div class="zusammensetzung-bulletpoint-left flex-row total">
                    <span>{{ option.tarifTypEnum === 'BASE' ? 'Verbrauchspreis pro kWh' : 'Verbrauchspreis pro kWh im 1. Monat' }}</span>
                  </div>
                  <span class="zusammensetzung-bulletpoint-right">
                    <span>{{ centValueFormatter(option.verbrauchspreisTotal) }}</span>
                  </span>

                </div>
              </div>
              <div class="zusammensetzung-wrapper">
                <div class="zusammensetzung-headline mb-1">Grundpreis <span class="zusammensetzung-headline-small">pro Monat</span>
                </div>
                <div class="flex-row mb-1">
                  <div class="zusammensetzung-bulletpoint-left flex-row">
                    <span class="mr-1">Netznutzungs- und Messstellengebühren</span>
                    <JouleTooltip class="mr-1" text="Beinhaltet Gebühren, die für die Nutzung und Instandhaltung des Stromnetzes sowie den Betrieb der Messstelle gem. AGB anfallen. Die Berechnung basiert auf den weit verbreiteten analogen Stromzählern mit einem Zählwerk." variant="light"></JouleTooltip>
                  </div>
                  <span class="zusammensetzung-bulletpoint-right">{{
                      euroValueFormatter(option.preisNetznutzung)
                    }}</span>
                </div>
                <div class="flex-row mb-1">
                  <div class="zusammensetzung-bulletpoint-left flex-row">
                    <span class="mr-1">GP JOULE Servicegebühr</span>
                  </div>
                  <span class="zusammensetzung-bulletpoint-right">{{
                      euroValueFormatter(option.preisServicegebuehr)
                    }}</span>
                </div>

                <div class="divider mb-1"></div>
                <div class="flex-row mb-1 zusammensetzung-bulletpoint-right">
                  <span>Grundpreis pro Monat</span>
                  <span>{{ euroValueFormatter(option.grundpreisTotal) }}</span>
                </div>
              </div>
              <div class="font-size-12 grey-text mb-1">
                {{ store.akquiseDaten.firma ? 'Preise zuzüglich der Umsatzsteuer in jeweils geltender Höhe (derzeit 19%).' : 'Die Preise enthalten die Umsatzsteuer in jeweils geltender Höhe (derzeit 19%).' }}
              </div>
              <content-box color="var(--gpj-grey-30, #CBCCCD)">
                <template #content>
                  <div class="font-size-12">Die dargestellten Preise basieren auf Ihren Angaben. Sollte Ihr Netzbetreiber abweichende Informationen für Ihre Messstelle an uns zurückmelden oder höhere Kosten für den Betrieb einer modernen Messeinrichtung oder Smart Meter ansetzen, behalten wir uns die entsprechende Anpassung von Grund- und Verbrauchspreis vor.
                  </div>
                </template>
              </content-box>
            </template>
          </Panel>
        </template>
      </content-box>

    </div>
  </div>

</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.tariff-wrapper {
  border: 2px solid var(--black);
  padding: 15px;
  border-radius: 8px;
  width: 100%;
}

.toggle-panel-header {
  width: 100%
}

.button {
  width: 100%;
}

.zusammensetzung-wrapper {
  margin-top: 15px;
}

.zusammensetzung-headline {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: var(--grey)

}

.zusammensetzung-headline-small {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: var(--grey)

}

.zusammensetzung-subline {
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: var(--grey)
}

.zusammensetzung-bulletpoint-left {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
}

.zusammensetzung-bulletpoint-right {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
  text-align: right;
}

.total {
  font-weight: 600;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--grey);
}
</style>

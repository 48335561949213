<script setup lang="ts">
import Card from "primevue/card";
import CheckBox from "primevue/checkbox";
import {useStore} from "@/store/AkquiseDatenStore";
import {useStore as useTarifDatenStore} from "@/store/TarifDatenStore";
import JouleButton from "@/components/JouleButton.vue";
import router from "@/router";
import ContentBox from "@/components/ContentBox.vue";
import {computed, ref} from "vue";
import {useRoute} from "vue-router";
import {ITarif} from "@/types/Tarif";
import dayjs from "dayjs";
import {centValueFormatter, euroValueFormatter} from "@/utils/NumberFormatter";
import {SparteEnum} from "@/types/SparteEnum";
import JouleTooltip from "@/components/JouleTooltip.vue";
import {useStore as useStepValidationStore} from "@/store/StepValidationStore";
import {AkquiseStepEnum, getIndexByStep, getRouteNameByStep} from "@/types/AkquiseStepEnum";
import {buttonClicked, handleJentis} from "@/utils/JentisService";

let akquiseDatenStore = useStore();
let stepValidationStore = useStepValidationStore();
let tarifDatenStore = useTarifDatenStore();
const route = useRoute();
const currentTarif = ref({} as ITarif);

const handleVerifizierung = async () => {
  if (route.query && route.query.code) {
    // verify email if code is present in the url
    await akquiseDatenStore.verifyEmail(route.query.code as string);
  } else if (!akquiseDatenStore.akquiseDaten.id) {
    // get data from cookie
    await akquiseDatenStore.getAkquiseDaten();
  }

  // Load Tarif data
  if (akquiseDatenStore.akquiseDaten.akquiseSession) {
    await tarifDatenStore.getTarifDaten(
        akquiseDatenStore.akquiseDaten.akquiseSession
    );
  }
  if (akquiseDatenStore.akquiseDaten.tarifId) {
    currentTarif.value = tarifDatenStore.getTarifById(
        akquiseDatenStore.akquiseDaten.tarifId
    );
    if (akquiseDatenStore.akquiseDaten.grundpreis !== currentTarif.value.grundpreisTotal || akquiseDatenStore.akquiseDaten.arbeitspreis !== currentTarif.value.verbrauchspreisTotal) {
      changedPreis.value = true;
    }
  }

  if (!akquiseDatenStore.akquiseDaten.id) {
    router.push({name: "Tarifrechner"});
  } else {
    // If the order has already been placed, the user will directly be redirected to the "Bestellbestaetigung"
    if (akquiseDatenStore.akquiseDaten.bestelltAm) {
      if (route.query && route.query.code) {
        router.push({
          name: "Bestellbestaetigung",
          query: {code: route.query.code as string},
        });
      } else {
        router.push({name: "Bestellbestaetigung"});
      }
    }
    await stepValidationStore.validate();
    if (getIndexByStep(AkquiseStepEnum.ZUSAMMENFASSUNG) > getIndexByStep(stepValidationStore.akquiuseDatenValidation.currentStep)) {
      stepValidationStore.showErrorsCount = 1;
      router.push({name: getRouteNameByStep(stepValidationStore.akquiuseDatenValidation.currentStep)});
    } else {
      // only handle Jentis if the user actually is allowed to be on the Zusammenfassung page
      handleJentis();
    }
  }
};

handleVerifizierung();

const next = async () => {
  if (!privacy.value) {
    errorActive.value = true;
    return;
  }
  buttonClicked('jetzt_bestellen_clicked')
  await akquiseDatenStore.postAkquiseDaten();
  await akquiseDatenStore.confirmOrder(
      currentTarif.value.grundpreisTotal,
      currentTarif.value.verbrauchspreisTotal
  );
  await router.push({
    name: "Bestellbestaetigung",
    query: {code: route.query.code as string},
  });
};

const back = () => {
  router.push({name: "Zahlungsdetails"});
};
const privacy = ref(false as boolean);
const errorActive = ref(false as boolean);
const changedPreis = ref(false as boolean);
const formatDate = (date: string) => {
  return dayjs(date, "YYYY-MM-DD").format("DD.MM.YYYY");
};

const sparteLabel = computed((): string => {
  if (akquiseDatenStore.akquiseDaten.sparte === SparteEnum.STROM) {
    if (akquiseDatenStore.akquiseDaten.firma) {
      return currentTarif.value.tarifname + " Gewerbestrom ";
    } else {
      return currentTarif.value.tarifname + " Haushaltsstrom ";
    }
  } else if (
      akquiseDatenStore.akquiseDaten.sparte === SparteEnum.WAERMEPUMPE ||
      akquiseDatenStore.akquiseDaten.sparte === SparteEnum.NACHTSPEICHERHEIZUNG
  ) {
    return currentTarif.value.tarifname + " Wärmestrom ";
  } else if (akquiseDatenStore.akquiseDaten.sparte === SparteEnum.AUTOSTROM) {
    return currentTarif.value.tarifname + " Autostrom ";
  } else {
    return "";
  }
});
</script>

<template>
  <div class="card-wrapper">
    <Card class="card">
      <template #title>
        <div class="card-headline-small">Zusammenfassung</div>
      </template>
      <template #content>
        <div class="mb-2">
          <content-box
              :show-edit-button="true"
              @edit-button-clicked="router.push({ name: 'Kontaktdaten' })"
          >
            <template #content>
              <div class="small-bold mb-1">Vertragsdaten</div>
              <div class="content-box-content content-box-text-bold-grey">
                <div
                    class="flex-row-together gap-45 mb-1"
                    v-if="akquiseDatenStore.akquiseDaten.firma"
                >
                  <div class="flex-row-together">
                    <i class="material-icons-round mr-10-px">factory</i>
                    <span>{{
                        akquiseDatenStore.akquiseDaten.lieferadresse.firma
                      }}</span>
                  </div>
                </div>

                <div
                    class="flex-row-together mb-1"
                    v-if="akquiseDatenStore.akquiseDaten.lieferadresse.nachname"
                >
                  <i class="material-icons-round mr-10-px">person</i>
                  <span
                  >{{ akquiseDatenStore.akquiseDaten.lieferadresse.vorname }}
                    {{
                      akquiseDatenStore.akquiseDaten.lieferadresse.nachname
                    }}</span
                  >
                </div>
                <div class="flex-row-together mb-1">
                  <i class="material-icons-round mr-10-px">location_on</i>
                  <span
                  >{{ akquiseDatenStore.akquiseDaten.lieferadresse.strasse }}
                    {{ akquiseDatenStore.akquiseDaten.lieferadresse.hausNr }},
                    {{ akquiseDatenStore.akquiseDaten.lieferadresse.plz }}
                    {{ akquiseDatenStore.akquiseDaten.lieferadresse.ort }}</span
                  >
                </div>
                <div
                    class="flex-row-together mb-1"
                    v-if="
                    akquiseDatenStore.akquiseDaten.abweichendeRechnungsadresse
                  "
                >
                  <i class="material-icons-round mr-10-px">receipt_long</i>
                  <span
                  >{{
                      akquiseDatenStore.akquiseDaten.rechnungsadresse.strasse
                    }}
                    {{
                      akquiseDatenStore.akquiseDaten.rechnungsadresse.hausNr
                    }},
                    {{ akquiseDatenStore.akquiseDaten.rechnungsadresse.plz }}
                    {{
                      akquiseDatenStore.akquiseDaten.rechnungsadresse.ort
                    }}</span
                  >
                </div>
                <div class="flex-row-together mb-1">
                  <i class="material-icons-round mr-10-px">pin</i>
                  <span>{{
                      akquiseDatenStore.akquiseDaten.zaehlerNummer
                    }}</span>
                </div>
                <div class="flex-row-together gap-45">
                  <div class="flex-row-together">
                    <i class="material-icons-round mr-10-px">mail</i>
                    <span>{{ akquiseDatenStore.akquiseDaten.email }}</span>
                  </div>
                  <div
                      class="flex-row-together"
                      v-if="akquiseDatenStore.akquiseDaten.telefonnummer"
                  >
                    <i class="material-icons-round mr-10-px">call</i>
                    <span>{{
                        akquiseDatenStore.akquiseDaten.telefonnummer
                      }}</span>
                  </div>
                </div>
              </div>
            </template>
          </content-box>
        </div>
        <div class="mb-2">
          <content-box
              :show-edit-button="true"
              @edit-button-clicked="router.push({ name: 'TarifrechnerDetail' })"
          >
            <template #content>
              <div class="small-bold mb-1">Tarif und Zahlung</div>
              <div class="content-box-content content-box-text-bold-grey">
                <div class="flex-row-together" :class="changedPreis ? 'mb-4px' : 'mb-1'">
                  <i class="material-icons-round mr-10-px">bolt</i>
                  <div class="flex-column non-aligned "><span
                  >{{ sparteLabel }} </span
                  >
                    <span class="content-box-text-regular">{{
                        akquiseDatenStore.akquiseDaten.verbrauch
                            ?.toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      }}
                    kWh im Jahr</span>
                  </div>
                </div>
                <div class="flex-row-together mb-1">
                  <i class="material-icons-round mr-10-px">euro_symbol</i>
                  <div class="flex-column non-aligned">
                    <div class="flex-row align-end gap-10"
                    ><span>{{ euroValueFormatter(currentTarif.price) }}
                      {{
                        currentTarif.garantie == "1 Monat"
                            ? "pro Monat"
                            : "pro Monat"
                      }}</span>
                      <div class="tooltip-wrapper" v-if="changedPreis">Preisanpassung
                        <JouleTooltip
                            text="Ihr Arbeits- sowie Grundpreis wurden angepasst und der Abschlag neu berechnet. Entweder haben Sie im Prozess angegeben, dass Sie einen Zweitarifzähler verbaut haben oder die Preise aus der Tarifübersicht haben Ihre Gültgikeit verloren."></JouleTooltip>
                      </div>
                    </div
                    >
                    <span
                        class="content-box-text-regular">Arbeitspreis: {{
                        centValueFormatter(currentTarif.verbrauchspreisTotal)
                      }}/kWh, Grundpreis: {{ euroValueFormatter(currentTarif.grundpreisTotal) }}/Monat</span>
                  </div>

                </div>
                <div class="flex-row-together mb-1">
                  <i class="material-icons-round mr-10-px">event</i>
                  <div class="flex-column non-aligned"> <span>{{
                      akquiseDatenStore.akquiseDaten.asFastAsPossible
                          ? "Schnellstmöglicher Lieferbeginn" :
                          akquiseDatenStore.akquiseDaten.wechsel ?
                              "Lieferung zu Wunschdatum"
                              : "Lieferung zu Umzugsdatum"
                    }}</span>
                    <span class="content-box-text-regular" v-if="!akquiseDatenStore.akquiseDaten.asFastAsPossible">
{{
                        akquiseDatenStore.akquiseDaten.wechsel
                            ? "Lieferbeginn am " + formatDate(
                            akquiseDatenStore.akquiseDaten.wechseltermin
                        )
                            : "Lieferbeginn am " + formatDate(
                            akquiseDatenStore.akquiseDaten.umzugstermin
                        )
                      }}
                  </span></div>

                </div>
                <div
                    class="flex-row-together"
                    :class="
                    akquiseDatenStore.akquiseDaten.aktionscode ? 'mb-1' : ''
                  "
                >
                  <i class="material-icons-round mr-10-px">info</i>
                  <div class="flex-column non-aligned"><span
                  >Zahlung per
                    {{
                      akquiseDatenStore.akquiseDaten.lastschrift
                          ? "Lastschrift"
                          : "Überweisung"
                    }}
                  </span>
                    <span v-if="akquiseDatenStore.akquiseDaten.lastschrift" class="content-box-text-regular">IBAN endet mit {{
                        akquiseDatenStore.akquiseDaten.iban
                            ? akquiseDatenStore.akquiseDaten.iban?.replaceAll(" ", "").slice(-4)
                            : ""
                      }}</span></div>

                </div>
                <div
                    class="flex-row-together"
                    v-if="akquiseDatenStore.akquiseDaten.aktionscode"
                >
                  <i class="material-icons-round mr-10-px"
                  >confirmation_number</i
                  >
                  <span
                  >{{ akquiseDatenStore.akquiseDaten.aktionscode }} ({{
                      akquiseDatenStore.akquiseDaten.aktionscodeSuccessMeldung
                    }})</span
                  >
                </div>
              </div>
            </template>
          </content-box>
        </div>
        <div class="flex-row-together gap-10 non-aligned mb-1">
          <CheckBox
              inputId="neuigkeitenCheckbox"
              v-model="akquiseDatenStore.akquiseDaten.optIn"
              :binary="true"
          ></CheckBox>
          <label for="neuigkeitenCheckbox" class="label-text">
            Ich möchte per E-Mail zu Produkten und Dienstleistungen der GP JOULE Gruppe informiert werden. Die
            Einwilligung kann jederzeit per E-Mail an info@plus.gp-joule.de widerrufen werden.
          </label>
        </div>
        <div class="mb-2">
          <div class="flex-row-together gap-10 non-aligned">
            <CheckBox
                inputId="agbDatenschutzCheckbox"
                :class="errorActive ? 'error' : ''"
                v-model="privacy"
                :binary="true"
            ></CheckBox>
            <label for="agbDatenschutzCheckbox" class="label-text">
              Ich akzeptiere die
              <a
                  class="link-text"
                  target="_blank"
                  href="https://www.plus-gp-joule.de/agb"
              >AGB</a
              >
              und habe die Hinweise zum
              <a
                  class="link-text"
                  target="_blank"
                  href="https://www.plus-gp-joule.de/datenschutz"
              >Datenschutz</a
              >
              und
              <a
                  class="link-text"
                  target="_blank"
                  href="https://www.plus-gp-joule.de/agb"
              >Widerrufsrecht</a
              >
              zur Kenntnis genommen.
            </label>
          </div>
          <div v-if="errorActive" class="error-text-wrapper">
            <span class="material-icons-round error-text mr-5-px">info</span>
            <div class="error-text">Bitte stimmen Sie den Bedingungen zu</div>
          </div>
        </div>

        <div class="flex-row">
          <JouleButton
              class="p-button-secondary mr-1 flex-shrink-4 min-w-60"
              icon="arrow_back"
              @click="back"
          ></JouleButton>
          <JouleButton
              label="Jetzt zahlungspflichtig bestellen"
              icon="arrow_forward"
              @click="next"
          ></JouleButton>
        </div>
      </template>
    </Card>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.tooltip-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  background: var(--grey-30);
  padding: 4px;
  border-radius: 8px;
}

.card-wrapper {
  color: grey;
}
</style>

import {EnergyPrice} from "@/types/EnergyPrice";

export const findEnergyPrices = async (): Promise<EnergyPrice[]> => {
    return fetch("/api/boersenpreis", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return null;
        }
    });
};

<script setup lang="ts">
import {defineModel, defineProps, defineEmits, PropType, ref, watch} from 'vue';
import JouleInputField from "@/components/JouleInputField.vue";

const props = defineProps({
  icon: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
    default: () => '',
  },
  query: {
    type: Function,
    required: true
  },
  inputFormatter: {
    type: Function,
    default: (value) => value
  },
  resultFormatter: {
    type: Function,
    default: (value) => value ? value : ''
  },
  errorText: {
    type: String,
    required: false,
    default: () => "",
  },
  inputValid: {
    type: Boolean,
    default: () => null
  },
  queryInitial: {
    type: Boolean,
    default: () => false
  }

});
const emits = defineEmits(['inputChanged']);

const model = defineModel({
  type: Object as PropType<any | null>,
  required: false
});
const options = ref([] as any[]);
const showOptions = ref(false);
const selectedIndex = ref(-1 as number);

const searchTerm = ref('' as string);

const queryItems = async () => {
  if (searchTerm.value.length === 0) {
    model.value = null;
    emits('inputChanged');
    return;
  }
  options.value = props.query ? await props.query(searchTerm.value) : [];
  if (options.value.length > 0) {
    showOptions.value = true;
  }
};

const setSearchTerm = () => {
  searchTerm.value = props.resultFormatter ? props.resultFormatter(model.value) : model.value;
  if (props.queryInitial) {
    queryItems()
  }
}
setSearchTerm()
watch(() => model, () => {
  setSearchTerm();
});

const handleSelect = (option: any) => {
  model.value = option;
  showOptions.value = false;
  searchTerm.value = props.resultFormatter ? props.resultFormatter(option) : option;
  emits('inputChanged')

};

const selectPrev = () => {
  if (selectedIndex.value > 0) {
    selectedIndex.value--
  } else {
    selectedIndex.value = options.value.length - 1
  }
};
const selectNext = () => {
  if (selectedIndex.value < options.value.length - 1) {
    selectedIndex.value++
  } else {
    selectedIndex.value = 0
  }
};
const selectItem = () => {
  if (selectedIndex.value >= 0) {
    handleSelect(options.value[selectedIndex.value])
  }
};


</script>
<template>
  <div class="input-field-wrapper dropdown">
    <JouleInputField type="text"
                     :label="label"
                     :input-valid="inputValid"
                     v-model="searchTerm"
                     :error-text="errorText"
                     @keydown.up="selectPrev"
                     @keydown.down="selectNext"
                     @keypress.enter="selectItem"
                     :placeholder="placeholder"
                     class="autocomplete-input"
                     @input="queryItems">
    </JouleInputField>

    <div class="typeahead-dropdown">
      <div v-if="showOptions" class="p-dropdown-panel p-component p-ripple-disabled dropdown-items-position-wrapper">
        <div class="p-dropdown-items-wrapper" >
          <ul class="p-dropdown-items">
            <li v-for="(option, index) in options" :class="index === selectedIndex ? 'p-dropdown-selected-item' : ''"
                :key="label + 'option' + index"
                class="p-dropdown-item"
                @click="handleSelect(option)">
              <div class="flex align-items-center flex-row flex-start gap-2">
            <span>
              {{ props.resultFormatter ? props.resultFormatter(option) : option }}
            </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.autocomplete-input {
  width: 100%;
  border-width: 0;
  outline: none;
  font-size: 1rem;
}

.dropdown {
  width: 100%;
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  max-height: 200px;
}

.p-dropdown-item {
  margin: 1em;
  width: 100%
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-dropdown-selected-item {
  color: rgba(0, 0, 0, .87);
  background: var(--black-07);
}

.dropdown-items-position-wrapper {
  z-index: 1001;
  position: absolute;
  transform-origin: center top;
  width: 100%
}

.typeahead-dropdown .p-dropdown-items-wrapper {
  overflow: auto;
  max-height: 200px;
  width: 100%
}

.typeahead-dropdown {
  position: relative;
}
</style>

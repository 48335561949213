import {NavigationGuardNext, RouteLocation} from "vue-router";
import {AkquiseStepEnum, getIndexByStep, getRouteNameByStep} from "@/types/AkquiseStepEnum";
import {useStore} from "@/store/StepValidationStore";
import {useStore as useAkquiseStore} from "@/store/AkquiseDatenStore";
import {getByString} from "@/types/VariantenEnum";
import {linkPrefixes} from "@/types/RefererLinkPresets";

export default async function (
    to: RouteLocation,
    from: RouteLocation,
    next: NavigationGuardNext
): Promise<void> {
    const validationStore = useStore();
    const akquiseStore = useAkquiseStore();
    // If TarifrechnerDetail with the request parameters is called TarifrechnerDetail can be accessed and the parameters are being posted
    if (to.query.product || to.query.customer || to.query.consumption || to.query.zip || to.query.promotion || to.query.referrer) {
        akquiseStore.akquiseDaten.variante = getByString(to.query.product as string);
        if (akquiseStore.akquiseDaten.variante !== null) {
            akquiseStore.hideVarianteSelect = true;
        }
        akquiseStore.akquiseDaten.firma = to.query.customer ? (to.query.customer !== 'consumer') : true;
        // if the present referrerlink includes https://www.gp-joule.com, http://www.gp-joule.com, https://gp-joule.com or http://gp-joule.com the links gets stored, otherwise is set to null
        akquiseStore.akquiseDaten.referrerLink = linkPrefixes.some(link => to.query.referrer?.includes(link)) ?
            to.query.referrer as unknown as string : null;
        akquiseStore.akquiseDaten.verbrauch = to.query.consumption as unknown as number;
        akquiseStore.akquiseDaten.lieferadresse!.plz = to.query.zip as unknown as string;
        akquiseStore.akquiseDaten.lieferadresse!.ort = to.query.city as unknown as string;
        akquiseStore.akquiseDaten.aktionscode = to.query.promotion ? to.query.promotion as unknown as string : null;
        await akquiseStore.postAkquiseDaten();
        if (!akquiseStore.akquiseDaten.lieferadresse!.plz || akquiseStore.akquiseDaten.lieferadresse!.plz.length === 0 ||
            !akquiseStore.akquiseDaten.verbrauch ||
            !akquiseStore.akquiseDaten.lieferadresse!.ort || akquiseStore.akquiseDaten.lieferadresse!.ort.length === 0) {
            // if plz, ort or verbrauch is not set, the user will directly be redirected to AkquiseStepEnum.VERBRAUCH.
            // This will lead to that the user won't see any error messages.
            next({
                name: getRouteNameByStep(AkquiseStepEnum.VERBRAUCH),
            });
        } else {
            next({
                name: getRouteNameByStep(AkquiseStepEnum.TARIF_AUSWAHL),
            });
        }
    } else {
        await validationStore.validate();
        const routeName = validationStore.akquiuseDatenValidation.currentStep
        if (routeName === AkquiseStepEnum.BESTAETIGUNG) {
            // If validationstore has returns AkquiseStepEnum.BESTAETIGUNG the AkquiseStepEnum.BESTAETIGUNGs page is the only one that he is allowed to view.
            next({
                name: getRouteNameByStep(AkquiseStepEnum.BESTAETIGUNG),
            });
        } else {
            // If the user tries to access a route that is not in the correct order, because he needs to fill out the previous steps first,
            // he will be redirected to the correct route and validationStore.showErrorsCount will be set to 1.
            if (to.meta.step !== routeName && getIndexByStep(to.meta.step as AkquiseStepEnum) >= getIndexByStep(routeName)) {
                if (from.path === '/') {
                    // if from.path === '/' the user is accessing the page for the first time and has not loaded the single page application before.
                    // So we set validationStore.showErrorsCount to one, because the guard is called once more
                    validationStore.showErrorsCount = 1;
                } else {
                    // if from.path !== '/' the user has loaded the single page application before.
                    // So we set validationStore.showErrorsCount to zero, because the guard will only be called this time.
                    validationStore.showErrorsCount = 2;
                }
                next({
                    name: getRouteNameByStep(routeName),
                });
            } else if (validationStore.showErrorsCount >= 2) {
                // If showErrorsCount is >= 2: set showErrorsCount to 0 in the Guard so the user can't see the errors anymore
                validationStore.showErrorsCount = 0;
                next();
            } else {
                // If showErrorsCount is 1: add +1 to showErrorsCount in the Guard
                if (validationStore.showErrorsCount > 0) {
                    validationStore.showErrorsCount = validationStore.showErrorsCount + 1;
                }
                next();
            }
        }
    }
}

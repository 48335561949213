import {createApp} from 'vue'
import PrimeVue from 'primevue/config';
import App from "@/App.vue";
import router from "./router";

import './assets/themes/gp_joule_plus_signup/theme.css';


import Card from 'primevue/card';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';
import Steps from 'primevue/steps';
import {createPinia} from "pinia";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import {useStore} from "@/store/JentisStore";


const app = createApp(App);

const pinia = createPinia();

app.component('SignupCard', Card);
app.component('SignupSelectButton', SelectButton);
app.component('SignupButton', Button);
app.component('SignupSteps', Steps);
app.component('SignupDropdown', Dropdown);
app.component('InputSwitch', InputSwitch);
app.component('SignupCalendar', Calendar);

app.use(PrimeVue, {
    locale: {
        dayNamesMin: ["So","Mo","Di","Mi","Do","Fr","Sa"],
        monthNames: ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"],
        monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        firstDayOfWeek: 1,
        }
});
app.use(router);
app.use(pinia);
app.mount("#app");
const jentisStore = useStore();

declare global {
    interface Window {
        jentisStore:any;
    }
}

(window as Window & typeof globalThis).jentisStore = jentisStore;

<script setup lang="ts">
import JouleInputField from "@/components/JouleInputField.vue";
import Card from "primevue/card";
import {useStore} from "@/store/AkquiseDatenStore";
import JouleButton from "@/components/JouleButton.vue";
import {useStore as useStepValidationStore} from "@/store/StepValidationStore";
import router from "@/router";
import JouleInputSwitch from "@/components/JouleInputSwitch.vue";
import {handleJentis} from "@/utils/JentisService";

handleJentis();

let akquiseDatenStore = useStore();
const stepValidationStore = useStepValidationStore();

const next = async () => {
  await akquiseDatenStore.postAkquiseDaten();
  router.push({name: "Wechsel"});
}

const back = () => {
  router.push({name: "Kontaktdaten"});
}
const validate = async () =>{
  await akquiseDatenStore.postAkquiseDaten();
  await stepValidationStore.validate();
}
</script>

<template>
  <div class="card-wrapper">
    <Card class="card">
      <template #title>
        <div class="card-headline-small">Ihre Lieferadresse</div>
      </template>
      <template #content>
        <div class="subline-text">An welche Adresse soll der Strom geliefert werden?</div>
        <div class="flex-row mb-2 non-aligned">
          <JouleInputField v-model="akquiseDatenStore.akquiseDaten.lieferadresse.strasse"
                           class="mr-1"
                           name="lieferadresse.strasse"
                           :error-text="stepValidationStore.getErrorByFieldName('lieferadresse.strasse')"
                           label="Straße" placeholder="Musterstraße" @input-changed="validate" @enter="next">
          </JouleInputField>
          <JouleInputField v-model="akquiseDatenStore.akquiseDaten.lieferadresse.hausNr"
                           class="flex-sh flex-shrink-2"
                           :error-text="stepValidationStore.getErrorByFieldName('lieferadresse.hausNr')"
                           label="Hausnummer" name="lieferadresse.hausNr" placeholder="1" @input-changed="validate" @enter="next">
          </JouleInputField>
        </div>
        <div class="mb-2">
          <JouleInputField optional="true" v-model="akquiseDatenStore.akquiseDaten.lieferadresse.adressZusatz"
                           class="flex-sh flex-shrink-2"
                           label="Adresszusatz" name="lieferadresse.adressZusatz" placeholder="Hinterhof" @input-changed="validate" @enter="next">
          </JouleInputField>
        </div>
        <div class="flex-row mb-2 non-aligned">
          <JouleInputField v-model="akquiseDatenStore.akquiseDaten.lieferadresse.plz"
                           class="mr-1 flex-shrink-2"
                           name="lieferadresse.plz"
                           disabled
                           :error-text="stepValidationStore.getErrorByFieldName('lieferadresse.plz')"
                           label="Postleitzahl"
                           tooltip="Die Berechnung Ihres Tarifes wurde anhand der angegebenen Postleitzahl vorgenommen. Wenn Sie diese ändern möchten, so wechseln Sie gern zurück zum Tarifrechner."
                           placeholder="24941" @input-changed="validate" @enter="next">
          </JouleInputField>
          <JouleInputField v-model="akquiseDatenStore.akquiseDaten.lieferadresse.ort"
                           label="Stadt" name="lieferadresse.ort" placeholder="Musterstadt"
                           tooltip="Die Berechnung Ihres Tarifes wurde anhand der angegebenen Stadt vorgenommen. Wenn Sie diese ändern möchten, so wechseln Sie gern zurück zum Tarifrechner."
                           disabled
                           :error-text="stepValidationStore.getErrorByFieldName('lieferadresse.ort')" @input-changed="validate" @enter="next">
          </JouleInputField>
        </div>
          <JouleInputSwitch input-id="abweichendeRechnungsadresseInputSwitch" class="mb-2" label="Abweichende Rechnungsadresse" v-model="akquiseDatenStore.akquiseDaten.abweichendeRechnungsadresse"></JouleInputSwitch>
        <div v-if="akquiseDatenStore.akquiseDaten.abweichendeRechnungsadresse">
          <div class="card-second-headline-small mb-1">Rechnungsadresse</div>
          <div class="flex-row mb-2 non-aligned">
            <JouleInputField v-model="akquiseDatenStore.akquiseDaten.rechnungsadresse.strasse"
                             class="mr-1"
                             name="rechnungsadresse.strasse"
                             :error-text="stepValidationStore.getErrorByFieldName('rechnungsadresse.strasse')"
                             label="Straße" placeholder="Musterstraße" @input-changed="validate" @enter="next">
            </JouleInputField>
            <JouleInputField v-model="akquiseDatenStore.akquiseDaten.rechnungsadresse.hausNr"
                             class="flex-sh flex-shrink-2"
                             :error-text="stepValidationStore.getErrorByFieldName('rechnungsadresse.hausNr')"
                             label="Hausnummer" name="rechnungsadresse.hausNr" placeholder="1" @input-changed="validate" @enter="next">
            </JouleInputField>
          </div>
          <div class="mb-2">
            <JouleInputField optional="true" v-model="akquiseDatenStore.akquiseDaten.rechnungsadresse.adressZusatz"
                             class="flex-sh flex-shrink-2"
                             :error-text="stepValidationStore.getErrorByFieldName('rechnungsadresse.adressZusatz')"
                             label="Adresszusatz" name="rechnungsadresse.adressZusatz" placeholder="Hinterhof" @input-changed="validate" @enter="next">
            </JouleInputField>
          </div>
          <div class="flex-row mb-2 non-aligned">
            <JouleInputField v-model="akquiseDatenStore.akquiseDaten.rechnungsadresse.plz"
                             class="mr-1 flex-shrink-2"
                             name="rechnungsadresse.plz"
                             label="Postleitzahl"
                             :error-text="stepValidationStore.getErrorByFieldName('rechnungsadresse.plz')"
                             placeholder="12345" @input-changed="validate" @enter="next">
            </JouleInputField>
            <JouleInputField v-model="akquiseDatenStore.akquiseDaten.rechnungsadresse.ort"
                             :error-text="stepValidationStore.getErrorByFieldName('rechnungsadresse.ort')"
                             label="Stadt" name="rechnungsadresse.ort" placeholder="Musterstadt" @input-changed="validate" @enter="next">
            </JouleInputField>
          </div>
        </div>

        <div class="flex-row">
          <JouleButton class="p-button-secondary mr-1 flex-shrink-4 min-w-60" icon="arrow_back"
                       @click="back"></JouleButton>
          <JouleButton label="Weiter" icon="arrow_forward" @click="next"></JouleButton>
        </div>

      </template>
    </Card>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";
</style>

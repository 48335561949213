<script setup lang="ts">
import InputNumber from "primevue/inputnumber";
import {defineModel, defineProps, ref, watch} from "vue";
import {defineEmits} from "vue/dist/vue";

const props = defineProps({
  buttonHeader: {
    type: String,
    default: ""
  },
  icon: {
    type: String,
    default: ""
  },
  suffix: {
    type: String,
    default: ""
  },
  placeholder: {
    type: String,
    default: ""
  },
  name: {
    type: String,
    default: ""
  },
  errorText: {
    type: String,
    default: () => ''
  },
  format: {
    type: Boolean,
    default: () => false
  }
})
const model = defineModel()

watch(model, (newModel, oldValue) => {
  if (newModel != oldValue) {
    emits('inputChanged')
  }
})

const value = ref()

let emits = defineEmits(['inputChanged','enter']);

</script>

<template>
  <div class="input-field-wrapper flex-column align-items-start">
    <div class="select-button-header">{{ props.buttonHeader }}</div>
    <div class="p-input p-input-icon-left">
      <i class="material-icons-round"
         :class="errorText != '' ? 'error' : ''">{{ props.icon }}</i>
      <InputNumber
          locale="de-DE"
          :name="props.name"
          class="with-icon"
          :class="errorText != '' ? 'error' : ''"
          v-model="model"
          :placeholder="props.placeholder"
          :suffix="props.suffix"
          @keyup.enter="$emit('enter')"
          :useGrouping="true"/>
      <div v-if="errorText!=''" class="error-text-wrapper">
        <span class="material-icons-round error-text mr-5-px">info</span>
        <div class="error-text">{{ errorText }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.select-button-header {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  margin-bottom: 10px;
}

.p-input {
  min-height: 40px;
}

.p-input-icon-left {
  width: 100%;
}

.input-field-wrapper {
  width: 100%
}
</style>

<script setup lang="ts">
import SelectButton from "primevue/selectbutton";

import {defineProps, defineEmits, defineModel, PropType} from "vue";
import {ISelectButtonModel} from "@/types/SelectButtonModel";
import JouleTooltip from "@/components/JouleTooltip.vue";

const props = defineProps({
  options: {type: Array as PropType<ISelectButtonModel[]>, required: true},
  label: {type: String, default: () => ""},
  tooltip:{type:String, default: ()=> ""}
})
defineEmits(['changed'])
const model = defineModel();
</script>

<template>
  <div class="select-button-wrapper">
    <div class="select-button-header flex-row-together gap-10">
      {{ props.label }}
      <JouleTooltip v-if="tooltip != ''" :text="tooltip"></JouleTooltip>
    </div>
    <SelectButton v-model="model" :allow-empty="false" :options="props.options"
                  option-value="value" @change="$emit('changed')">
      <template #option="slotProps">
        <div class="select-button-content">
          <i class="material-icons-round mr-10-px" v-if="slotProps.option.icon">{{ slotProps.option.icon }}</i>
          <span class="top-space small-semi-bold">{{ slotProps.option.label }}</span>
        </div>
      </template>
    </SelectButton>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.select-button-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.top-space {
  margin-top: 4px;
}


.select-button-header {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  margin-bottom: 10px;
}

.select-button-wrapper {
  width: 100%
}
</style>

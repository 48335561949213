<script setup lang="ts">
import Card from "primevue/card";
import {useStore} from "@/store/AkquiseDatenStore";
import JouleButton from "@/components/JouleButton.vue";
import router from "@/router";
import JouleSelectButton from "@/components/JouleSelectButton.vue";
import {ref} from "vue";
import JouleDropdown from "@/components/JouleDropdown.vue";
import JouleCalendar from "@/components/JouleCalendar.vue";
import ContentBox from "@/components/ContentBox.vue";
import dayjs from "dayjs";
import {ISelectButtonModel} from "@/types/SelectButtonModel";
import {useStore as useStepValidationStore} from "@/store/StepValidationStore";
import {getLieferanten} from "@/api/LieferantenApi";
import {handleJentis} from "@/utils/JentisService";
import CheckBox from "primevue/checkbox";

handleJentis();

let akquiseDatenStore = useStore();
const stepValidationStore = useStepValidationStore();

const next = async () => {
  setCorrectDates();
  await akquiseDatenStore.postAkquiseDaten();
  router.push({name: "Zaehler"});
};

const validate = async () => {
  await akquiseDatenStore.postAkquiseDaten();
  await stepValidationStore.validate();
};

const options = [
  {label: "Tarifwechsel", value: true, icon: "swap_horiz"},
  {
    label: "Umzug",
    value: false,
    icon: "local_shipping",
  },
];

const lieferOptions = [
  {label: "Schnellstmöglich", value: true, icon: "acute"},
  {
    label: "Wunschdatum",
    value: false,
    icon: "event",
  },
];

const lieferanten = ref([] as ISelectButtonModel[]);

const setLieferanten = async () => {
  lieferanten.value = (await getLieferanten())
      .map((o): ISelectButtonModel => {
        return {label: o.name, value: o.name};
      })
      .sort((a, b) => a.label.localeCompare(b.label));
};

setLieferanten();

const back = () => {
  router.push({name: "Lieferadresse"});
};
const setCorrectDates = () => {
  if (!isValidDate(akquiseDatenStore.akquiseDaten.wechseltermin)) {
    akquiseDatenStore.akquiseDaten.wechseltermin = null;
  }
  if (!isValidDate(akquiseDatenStore.akquiseDaten.umzugstermin)) {
    akquiseDatenStore.akquiseDaten.umzugstermin = null;
  }
  if(!akquiseDatenStore.akquiseDaten.wechsel){
    akquiseDatenStore.akquiseDaten.wechseltermin = null;
    akquiseDatenStore.akquiseDaten.asFastAsPossible = false;
  }
  else {
    if (akquiseDatenStore.akquiseDaten.asFastAsPossible) {
      akquiseDatenStore.akquiseDaten.wechseltermin = null;
    }
    akquiseDatenStore.akquiseDaten.umzugstermin = null;
  }
};

const isValidDate = (dateString) => {
  if (!dateString) return false;
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
};
</script>

<template>
  <div class="card-wrapper">
    <Card class="card">
      <template #title>
        <div class="card-headline-small">Details zu Ihrem Wechsel</div>
      </template>
      <template #content>
        <JouleSelectButton
            v-model="akquiseDatenStore.akquiseDaten.wechsel"
            :options="options"
            class="mb-2"
            label="Wechsel oder Umzug?"
        >
        </JouleSelectButton>
        <div v-if="akquiseDatenStore.akquiseDaten.wechsel">
          <JouleDropdown
              v-model="akquiseDatenStore.akquiseDaten.aktuellerLieferant"
              :options="lieferanten"
              label="Aktueller Lieferant"
              optionLabel="name"
              option-value="value"
              filter
              @change="validate"
              :error-text="
              stepValidationStore.getErrorByFieldName('aktuellerLieferant')
            "
              placeholder="Lieferant auswählen"
              class="mb-2"
          >
          </JouleDropdown>
          <JouleSelectButton
              v-model="akquiseDatenStore.akquiseDaten.asFastAsPossible"
              :options="lieferOptions"
              class="mb-2"
              label="Gewünschter Lieferbeginn"
          >
          </JouleSelectButton>
          <JouleCalendar
              v-if="!akquiseDatenStore.akquiseDaten.asFastAsPossible"
              v-model="akquiseDatenStore.akquiseDaten.wechseltermin"
              label="Wunschdatum"
              :error-text="
              stepValidationStore.getErrorByFieldName('wechseltermin')
            "
              icon="calendar_month"
              :input-valid="stepValidationStore.akquiuseDatenValidation.wechselterminValid"
              @input-changed="validate"
              class="mb-2"
              :min="dayjs().add(3, 'weeks').format('YYYY-MM-DD')"
              :max="dayjs().add(1, 'year')"
          >
          </JouleCalendar>

          <div
              class="mb-2"
          >
            <div class="flex-row-together gap-10 non-aligned">
              <CheckBox
                  inputId="wechselCheckbox"
                  :class="
                  stepValidationStore.getErrorByFieldName(
                    'wechselBelieferungCheckbox'
                  )
                    ? 'error'
                    : ''
                "
                  v-model="
                  akquiseDatenStore.akquiseDaten.wechselBelieferungCheckbox
                "
                  :binary="true"
              ></CheckBox>
              <label for="wechselCheckbox" class="label-text">
                Ich bin ausdrücklich einverstanden, dass die GP JOULE Plus GmbH
                mich bereits vor Ablauf der Widerrufsfrist zu den vereinbarten
                Vertragsbedingungen beliefert, wenn ein schnellerer Wechsel
                möglich ist.
              </label>
            </div>
            <div
                v-if="
                stepValidationStore.getErrorByFieldName(
                  'wechselBelieferungCheckbox'
                )
              "
                class="error-text-wrapper"
            >
              <span class="material-icons-round error-text mr-5-px">info</span>
              <div class="error-text">
                {{
                  stepValidationStore.getErrorByFieldName(
                      "wechselBelieferungCheckbox"
                  )
                }}
              </div>
            </div>
          </div>

          <content-box class="mb-2">
            <template #content>
              <span class="content-box-text-regular"
              >Ich bevollmächtige die GP JOULE Plus GmbH, falls erforderlich,
                den derzeitigen Stromliefervertrag mit einem anderen Lieferanten
                in meinem Namen zu kündigen.</span
              >
            </template>
          </content-box>
        </div>

        <div v-if="!akquiseDatenStore.akquiseDaten.wechsel">
          <JouleCalendar
              v-model="akquiseDatenStore.akquiseDaten.umzugstermin"
              label="Umzugsdatum"
              :error-text="
              stepValidationStore.getErrorByFieldName('umzugstermin')
            "
              @input-changed="validate"
              :input-valid="stepValidationStore.akquiuseDatenValidation.umzugsterminValid"
              :min="dayjs().subtract(6, 'weeks').format('YYYY-MM-DD')"
              :max="dayjs().add(1, 'year')"
              icon="calendar_month"
              class="mb-2"
          >
          </JouleCalendar>
          <div class="mb-2">
            <div class="flex-row-together gap-10 non-aligned">
              <CheckBox
                  inputId="wechselCheckbox"
                  :class="
                  stepValidationStore.getErrorByFieldName(
                    'wechselBelieferungCheckbox'
                  )
                    ? 'error'
                    : ''
                "
                  v-model="
                  akquiseDatenStore.akquiseDaten.wechselBelieferungCheckbox
                "
                  :binary="true"
              ></CheckBox>
              <label for="wechselCheckbox" class="label-text">
                Ich bin ausdrücklich einverstanden, dass die GP JOULE Plus GmbH
                mich bereits vor Ablauf der Widerrufsfrist zu den vereinbarten
                Vertragsbedingungen beliefert, wenn ein schnellerer Wechsel
                möglich ist.
              </label>
            </div>
            <div
                v-if="
                stepValidationStore.getErrorByFieldName(
                  'wechselBelieferungCheckbox'
                )
              "
                class="error-text-wrapper"
            >
              <span class="material-icons-round error-text mr-5-px">info</span>
              <div class="error-text">
                {{
                  stepValidationStore.getErrorByFieldName(
                      "wechselBelieferungCheckbox"
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex-row">
          <JouleButton
              class="p-button-secondary mr-1 flex-shrink-4 min-w-60"
              icon="arrow_back"
              @click="back"
          ></JouleButton>
          <JouleButton
              label="Akzeptieren & Weiter"
              icon="arrow_forward"
              @click="next"
          ></JouleButton>
        </div>
      </template>
    </Card>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.description-text {
  color: var(--GPJ-Grey, var(--GPJ-Grey, #545759));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}
</style>

<script setup lang="ts">
import {defineModel, defineProps, defineEmits, PropType, ref, computed, watch} from 'vue';
import {GPJDropdownStates} from "@/types/GPJDropdownStates";
import {GPJDropdownVariants} from "@/types/GPJDropdownVariants";
import {ISelectButtonModel} from "@/types/SelectButtonModel";

const props = defineProps({
  icon: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
  filter: {
    type: Boolean,
    default: () => false,
  },
  initState: {
    type: Number as PropType<GPJDropdownStates>,
    required: false,
    default: GPJDropdownStates.EMPTY,
  },
  variant: {
    type: Number as PropType<GPJDropdownVariants>,
    required: false,
    default: GPJDropdownVariants.DEFAULT,
  },
  options: {
    type: Array as PropType<ISelectButtonModel[]>,
    required: false,
    default: () => [],
  },
  label: {
    type: String,
    required: false,
    default: () => '',
  },
  optionValue: {
    type: String,
    required: false,
    default: () => null,
  },
  errorText: {
    type: String,
    required: false,
    default: () => "",
  },
});

const model = defineModel({
  type: Object as PropType<ISelectButtonModel | number | boolean | null>,
  required: false
});

const state = ref(props.initState as GPJDropdownStates);

let preselectedValue = '';

const searchTerm = ref(preselectedValue as string);
const setSearchTerm = () => {
  if (props.filter) {
    if (model && model.value && props.options && props.options.length > 0) {
      if (props.optionValue) {
        searchTerm.value = model.value ? props.options?.filter(o => o[props.optionValue] === model.value)[0].label : '';
      } else {
        searchTerm.value = model.value ? model.value.label : '';

      }
    }
  }
}
setSearchTerm()
watch(() => model, () => {
  setSearchTerm();
});
watch(() => props.options, () => {
  setSearchTerm();
});
const dropdownRef = ref(null as any);
const openDropdown = () => {
  dropdownRef.value.show()
};

const emit = defineEmits({
  change(option: ISelectButtonModel | undefined) {
    return true;
  },
  stateChange(states: { old: GPJDropdownStates; new: GPJDropdownStates }) {
    return true;
  },
});

const changeState = (newState: GPJDropdownStates) => {
  emit('stateChange', {old: state.value, new: newState});
  state.value = newState;
};
const itemRecentlySelected = ref(!!model.value)
const onChange = (selectedItem) => {
  if (props.filter) {
    itemRecentlySelected.value = true;
    if (props.optionValue) {
      searchTerm.value = selectedItem.value ? props.options?.filter(o => o[props.optionValue] === selectedItem.value)[0].label : '';
    } else {
      searchTerm.value = selectedItem.value ? selectedItem.value.label : '';
    }
  }
  emit('change', selectedItem.value);
  if (typeof model.value !== 'undefined') {
    changeState(GPJDropdownStates.FILLED);
  } else {
    changeState(GPJDropdownStates.EMPTY);
  }
};

const filteredOptions = computed(() => {
  return itemRecentlySelected.value ? props.options : props.options.filter(o => o.label.toLowerCase().includes(searchTerm.value.toLowerCase()))
})

const onHide = () => {
  if (typeof model.value !== 'undefined') {
    changeState(GPJDropdownStates.FILLED);
  } else {
    changeState(GPJDropdownStates.EMPTY);
  }
};

const clear = () => {
  searchTerm.value = '';
  model.value = null;
};

</script>
<template>

  <div class="input-field-wrapper">
    <div class="input-text-header">
      {{ props.label }}
    </div>
    <SignupDropdown
        ref="dropdownRef"
        class="dropdown flex align-items-center"
        :class="[GPJDropdownStates[errorText.length > 0 ? GPJDropdownStates.EMPTY_ERROR : state].toLowerCase(), GPJDropdownVariants[variant].toLowerCase()]"
        :options="filteredOptions"
        option-label="label"
        :option-value="optionValue"
        v-model="model"
        @change="onChange"
        emptyFilterMessage="Keinen passenden Eintrag gefunden"
        emptyMessage="Keinen passenden Eintrag gefunden"
        @show="changeState(GPJDropdownStates.FOCUSED)"
        @hide="onHide"
    >
      <template #value>
        <div class="placeholder flex align-items-center gap-2 text-sm"
             style="display: flex; width: 100%; height: 36px; flex-direction: row; align-items: center;">
          <span v-if="icon" class="material-icons-round">{{ icon }}</span>
          <input type="text"
                 @input="itemRecentlySelected = false"
                 v-model="searchTerm"
                 v-if="filter"
                 :placeholder="placeholder"
                 style="width: 100%; height: 30px; border-width: 0;outline: none; font-size: 1rem"
                 @click="openDropdown">
          <span v-if="!filter" style="padding-top: 4px">{{
              model ? options.filter(o => o.value === model)[0].label : placeholder
            }}</span>
          <span v-if="filter && searchTerm.length" @click="clear" class="material-icons-round">close</span>
        </div>
      </template>
      <template #dropdownicon>
        <i class="material-icons-round">arrow_drop_down</i>
      </template>
      <template #option="slotProps">
        <div class="flex align-items-center flex-row flex-start gap-2">
          <span v-if="slotProps.option.icon" class="material-icons-round">{{ slotProps.option.icon }}</span>
          <span class="pt-6-px pl-1">{{ slotProps.option.label }}</span>
        </div>
      </template>
    </SignupDropdown>
    <div v-if="errorText!=''" class="error-text-wrapper">
      <span class="material-icons-round error-text mr-5-px">info</span>
      <div class="error-text">{{ errorText }}</div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "@/assets/scss/style.scss";


.dropdown {
  width: 100%;
  height: 40px;
  border: 2px solid;
  padding-right: 1rem;

  .icon {
    color: var(--text-color);
  }

  &.empty {
    border-color: var(--grey);
    color: var(--grey);

    .placeholder {
      color: var(--grey);
    }
  }

  &.empty_error {
    border-color: var(--error-color);
    color: var(--error-color);

    ::placeholder {
      color: var(--error-color);
    }
  }

  &.focused {
    color: var(--grey);
  }

  &.filled {
    border-color: var(--grey);
    color: var(--grey);
  }

  &.filled_error {
    border-color: var(--pv-red-400);
    color: var(--pv-red-400);
  }

  /* variant contract */
  &.contract {
    background-color: var(--grey-30);
    border-color: var(--grey-30);
    color: var(--text-color);
    font-weight: 600;

    &.focused {
      border-color: var(--grey-50);
    }

    &.filled {
      border-color: var(--grey-30);
    }

    &.filled_error {
      border-color: var(--pv-red-400);
      color: var(--pv-red-400);
    }
  }
}

.dropdown.default {
  background-color: var(--white);

  &.focused {
    border-color: var(--yellow);
  }
}

</style>

import {defineStore} from "pinia";
import {AkquiseDatenValidationModel} from "@/types/AkquiseDatenValidationModel";
import {validateSession} from "@/api/AkquiseDatenApi";
import {AkquiseStepEnum} from "@/types/AkquiseStepEnum";

interface State {
    akquiuseDatenValidation: AkquiseDatenValidationModel,
    // We need showErrorsCount to be an Integer because the user will be redirected to the correct route and on redirect
    // the Guard will be called again. So we need to add 1 to showErrorsCount on this redirect. Wich will result in the following:
    // If showErrorsCount is 0: don't show errors
    // If showErrorsCount is greater than 0: show errors
    // If showErrorsCount is 1: add +1 to showErrorsCount in the Guard
    // If showErrorsCount is >= 2: set showErrorsCount to 0 in the Guard so the user can't see the errors anymore
    showErrorsCount: number
}

export const useStore = defineStore("valitionStore", {
    state: (): State =>
        ({
            akquiuseDatenValidation: {
                currentStep: AkquiseStepEnum.VERBRAUCH,
                errors: null,
                aktionscodeValid: false,
                lieferadressePlzValid: false,
                emailValid: false,
                geburtsdatumValid: false,
                umzugsterminValid: false,
                wechselterminValid: false,
            },
            showErrorsCount: 0,
        }),
    getters: {
        getErrorByFieldName: (state: State) => {
            return (errorFieldName: string) => {
                if (state.akquiuseDatenValidation &&
                    state.akquiuseDatenValidation.errors &&
                    state.akquiuseDatenValidation.errors[errorFieldName] &&
                    state.showErrorsCount > 0) {
                    return state.akquiuseDatenValidation.errors[errorFieldName];
                } else {
                    return "";
                }
            }
        },
        getAnyErrorByFieldName: (state: State) => {
            return (errorFieldName: string) => {
                if (state.akquiuseDatenValidation &&
                    state.akquiuseDatenValidation.errors &&
                    state.akquiuseDatenValidation.errors[errorFieldName]) {
                    return state.akquiuseDatenValidation.errors[errorFieldName];
                } else {
                    return "";
                }
            }
        },
    },
    actions: {
        async validate() {
            const validation = await validateSession();
            if (validation) {
                this.akquiuseDatenValidation = validation;
            }
        },
    },
});

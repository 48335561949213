<script setup lang="ts">
import {defineProps, ref, watch} from "vue";
import {AkquiseStepEnum, getIndexByStep, getRouteNameByStep} from "@/types/AkquiseStepEnum";
import router from "@/router";
import JouleButton from "@/components/JouleButton.vue";
import {useStore} from "@/store/StepValidationStore";
import {useStore as useAkquiseStore} from "@/store/AkquiseDatenStore";
import {useRoute} from "vue-router";

const props = defineProps({step: {type: String, default: AkquiseStepEnum.NONE}})

const setSteps = (nextErrorStep: AkquiseStepEnum) => {
  return [
    {
      label: "Kontaktdaten",
      labelSmall: "Kontaktdaten",
      labelDwarf: "Kontaktdaten",
      labelMinor: "Kontakt...",
      active: getIndexByStep(props.step) == getIndexByStep(AkquiseStepEnum.KONTAKTDATEN) || getIndexByStep(props.step) == getIndexByStep(AkquiseStepEnum.ADRESSE),
      clickable: getIndexByStep(nextErrorStep) >= getIndexByStep(AkquiseStepEnum.ADRESSE),
      done: getIndexByStep(nextErrorStep) > getIndexByStep(AkquiseStepEnum.ADRESSE),
      route: "Kontaktdaten"
    },
    {
      label: "Wechseldetails",
      labelSmall: "Wechseldetails",
      labelDwarf: "Wechsel...",
      labelMinor: "Wechsel...",
      active: getIndexByStep(props.step) == getIndexByStep(AkquiseStepEnum.LIEFERBEGINN) || getIndexByStep(props.step) == getIndexByStep(AkquiseStepEnum.ZAEHLER),
      clickable: getIndexByStep(nextErrorStep) >= getIndexByStep(AkquiseStepEnum.ZAEHLER),
      done: getIndexByStep(nextErrorStep) > getIndexByStep(AkquiseStepEnum.ZAEHLER),
      route: "Wechsel"
    },
    {
      label: "Zahlung",
      labelSmall: "Zahlung",
      labelDwarf: "Zahlung",
      labelMinor: "Zahlung",
      active: getIndexByStep(props.step) == getIndexByStep(AkquiseStepEnum.ZAHLUNG),
      clickable: getIndexByStep(nextErrorStep) >= getIndexByStep(AkquiseStepEnum.ZAHLUNG),
      done: getIndexByStep(nextErrorStep) > getIndexByStep(AkquiseStepEnum.ZAHLUNG),
      route: "Zahlungsdetails"
    },
    {
      label: "Verifizierung",
      labelSmall: "Verifizierung",
      labelDwarf: "Verifizierung",
      labelMinor: "Verifi...",
      active: getIndexByStep(props.step) == getIndexByStep(AkquiseStepEnum.EMAIL_VERIFIZIEREN),
      clickable: getIndexByStep(nextErrorStep) >= getIndexByStep(AkquiseStepEnum.EMAIL_VERIFIZIEREN),
      done: getIndexByStep(nextErrorStep) > getIndexByStep(AkquiseStepEnum.EMAIL_VERIFIZIEREN),
      route: "EmailVerifizierung"
    },
    {
      label: "Zusammenfassung",
      labelSmall: "Zusammf...",
      labelDwarf: "Zusammf...",
      labelMinor: "Zusammf...",
      active: getIndexByStep(props.step) == getIndexByStep(AkquiseStepEnum.ZUSAMMENFASSUNG),
      clickable: getIndexByStep(nextErrorStep) == getIndexByStep(AkquiseStepEnum.ZUSAMMENFASSUNG),
      done: getIndexByStep(nextErrorStep) == getIndexByStep(AkquiseStepEnum.BESTAETIGUNG),
      route: "Zusammenfassung"
    }
  ]
}

const getLabel = (step): string => {
  if (innerWidth.value < 340) {
    return step.labelMinor;
  } else if (innerWidth.value < 480) {
    return step.labelDwarf;
  } else if (innerWidth.value < 680) {
    return step.labelSmall;
  } else {
    return step.label;
  }
}
const store = useAkquiseStore();
const steps = ref(setSteps(AkquiseStepEnum.VERBRAUCH));
const stepVstore = useStore();
const route = useRoute();

watch(() => stepVstore.akquiuseDatenValidation.currentStep, () => {
  steps.value = setSteps(stepVstore.akquiuseDatenValidation.currentStep)
})

watch(() => props.step, async () => {
  await initSetSteps()
});
const initSetSteps = async () => {
  if (route.name == "Bestellbestaetigung") {
    steps.value = setSteps(AkquiseStepEnum.BESTAETIGUNG)
  } else {
    steps.value = setSteps(stepVstore.akquiuseDatenValidation.currentStep);
  }
}
initSetSteps();
const redirect = (step: any) => {
  if (router.currentRoute.value.name != "Bestellbestaetigung") {
    if (step.done || step.clickable) {
      router.push({name: step.route})
    }
  }
}

const flexDirectionClass = ref(window.innerWidth > 1150 ? 'container-row' : 'container-column');
const innerWidth = ref(window.innerWidth);
window.addEventListener('resize', () => {
  flexDirectionClass.value = window.innerWidth > 1150 ? 'container-row' : 'container-column';
  innerWidth.value = window.innerWidth;
})


const backToWebsite = () => {
  window.location.href = store.akquiseDaten.referrerLink ? store.akquiseDaten.referrerLink : "https://www.gp-joule.com/de/strom";
};

</script>

<template>
  <div :class="flexDirectionClass">
    <!-- we need an empty div for the stepper to be displayed in center if the backbutton is shown -->
    <div class="box" v-if="$route.meta.showBackButton" style="width: 0"></div>
    <div v-if="$route.meta.showStepper" v-show="step != AkquiseStepEnum.NONE" class="progress-step-wrapper box">
      <div class="single-step" v-for="(step,index) in steps" v-bind:key="step">
        <div v-if="index != 0" class="separator mt-6-px"></div>
        <div class="step-col">
          <div class="step mb-1"
               :class="{'active': step.active, 'inactive': !step.active, 'done': step.done && router.currentRoute.value.name != 'Bestellbestaetigung', 'clickable' : step.clickable}"
               @click="redirect(step)">
            <i class="material-icons-round small-white" v-if="step.done">check</i>
          </div>
          <div class="small-text step-label"
               :class="{'active': step.active, 'inactive': !step.active, 'done': step.done && router.currentRoute.value.name != 'Bestellbestaetigung'}"
               @click="redirect(step)">
            {{ getLabel(step) }}
          </div>
        </div>
      </div>
    </div>
    <div class="box" v-if="$route.meta.showBackButton">
      <div>
        <JouleButton
            class="back-button p-button-secondary"
            icon-pos="left"
            icon="logout"
            label="Zurück zur Website"
            @click="backToWebsite"
        ></JouleButton>
        <div v-if="step == AkquiseStepEnum.VERBRAUCH" class="to-kundenportal-button mt-1 flex-column mr-30-px mb--40-px">
          <div>Sie sind bereits Kunde?</div>
          <div><a
              class="link-text"
              href="https://portal.gp-joule.com/"
          >Zum Kundenportal</a
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.container-row {
  display: flex;
  flex-direction: row;
}

.container-column {
  display: flex;
  flex-direction: column;
}

.box {
  flex: 1;
  display: flex;
  justify-content: end;
}

.box:first-child > span {
  margin-right: auto;
}

.box:last-child > span {
  margin-left: auto;
}

.back-button {
  margin-top: 30px;
  margin-right: 30px;
  width: 220px;
  color: var(--GPJ-Black, var(--GPJ-Black, #000));
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px; /* 120% */
}

@media screen and (max-width: 769px) {
  .back-button {
    margin-right: 15px;
  }
}

.progress-step-wrapper {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.step-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15px;
}

.single-step {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.separator {
  width: 90px;
  height: 0;
  border: 1px solid var(--grey-50);
  border-radius: 2px;
}

@media all and (max-width: 680px) {
  .separator {
    width: 50px;
  }
}

@media all and (max-width: 480px) {
  .separator {
    width: 30px;
  }
}

@media all and (max-width: 340px) {
  .separator {
    width: 10px;
  }
}

.inactive {
  color: var(--grey-50)
}

.done {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.step {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: black;
    color: grey;
  }

  &.inactive {
    background: grey;
    color: grey;
  }
}

.small-white {
  color: white;
  font-size: 11px;
}
</style>

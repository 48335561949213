<script setup lang="ts">
  import { ref } from 'vue';
  import { ChartOptions } from 'chart.js';
  import {defineProps} from 'vue';
  import Chart from 'primevue/chart';
  const props = defineProps(['chartType']);


  const chartData = ref({
    labels: Array.from({ length: 24 }, (_, index) => index.toString()),
    datasets: [
      {
        label: 'Green Energy Percentage',
        data: [20, 10, 15, 25, 40, 55, 70, 90, 80, 65, 45, 50, 60, 70, 80, 90, 85, 75, 65, 60, 45, 30, 20, 15],
        fill: false,
        borderWidth: 2,
        tension: 0,
        stepped: 'middle',
        pointRadius: 0,
        borderColor: 'rgba(239, 239, 239, 1)',
        backgroundColor: 'rgba(239, 239, 239, 1)',
        hoverBorderColor: 'rgba(239, 239, 239, 1)',
        hoverBackgroundColor: 'rgba(239, 239, 239, 1)',
        borderRadius: 4,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: 'rgba(0, 0, 0, 0.5)',
        pointHitRadius: 10,
      },
    ],
  });

  const chartOptions = ref({
    devicePixelRatio: 4,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },

    scales: {
      x: {
        border: {
          display: false,
        },
        ticks: {
          callback: () => '   ', // Empty callback to hide ticks
          maxTicksLimit: 6,
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        ticks: {
          callback: () => '        ', // Empty callback to hide ticks
          display: false,
        },
        max: 100,
        min: 0,
        grid: {
          display: false,
        },
      },
    },
  } as ChartOptions);
</script>

<template>
  <Chart  :type="props.chartType" class="skeleton-chart" :data="chartData" :options="chartOptions"></Chart>
</template>
<style scoped lang="scss">
@import "@/assets/scss/_skeleton.scss";

  .skeleton-chart {
    height: calc(300px - 5.5rem);
    width: calc(100% - 20px - 1rem);
  }

</style>

<script setup lang="ts">
import TarifrechnerKundenartSwitch from "@/components/TarifrechnerKundenartSwitch.vue";
import {useStore} from "@/store/AkquiseDatenStore";
import {useStore as useStepValidationStore} from "@/store/StepValidationStore";
import JouleInputNumber from "@/components/JouleInputNumber.vue";
import JouleInputSwitch from "@/components/JouleInputSwitch.vue";
import TarifrechnerAktionscode from "@/components/TarifrechnerAktionscode.vue";
import ContentBox from "@/components/ContentBox.vue";
import JouleIncrementInput from "@/components/JouleIncrementInput.vue";
import {VerbrauchsVorauswahl} from "@/types/VerbrauchVorauswahl";
import {defineEmits, ref} from "vue";
import JouleAutoComplete from "@/components/JouleAutoComplete.vue";
import {IPlzOrt} from "@/types/PlzOrt";
import {getPlzOrt} from "@/api/PlzOrtApi";

const akquiseDatenStore = useStore();
const stepValidationStore = useStepValidationStore();
const validate = async () => {
  await akquiseDatenStore.postAkquiseDaten();
  await stepValidationStore.validate();
};
const emits = defineEmits(['user-changed-input','enter']);
const userChangedInput = async () => {
  emits("user-changed-input");
  await validate();
}

const query = async (searchTerm: string): Promise<IPlzOrt[]> => {
  if (searchTerm.length < 3) return [];
  return await getPlzOrt(searchTerm);
}

const formatInput = (value: IPlzOrt): string => {
  return value ? `${value.plz} ${value.ort}` : '';
}

const plz = ref(akquiseDatenStore.akquiseDaten.lieferadresse.plz || akquiseDatenStore.akquiseDaten.lieferadresse.ort ? {
  plz: akquiseDatenStore.akquiseDaten.lieferadresse.plz ? akquiseDatenStore.akquiseDaten.lieferadresse.plz : '',
  ort: akquiseDatenStore.akquiseDaten.lieferadresse.ort ? akquiseDatenStore.akquiseDaten.lieferadresse.ort : ''
} : null as IPlzOrt | null);

const plzChanged = async () => {
  if (plz.value) {
    akquiseDatenStore.akquiseDaten.lieferadresse.plz = plz.value.plz;
    akquiseDatenStore.akquiseDaten.lieferadresse.ort = plz.value.ort;
  } else {
    akquiseDatenStore.akquiseDaten.lieferadresse.plz = null;
    akquiseDatenStore.akquiseDaten.lieferadresse.ort = null;
  }
  await validate();
}
</script>

<template>
  <TarifrechnerKundenartSwitch></TarifrechnerKundenartSwitch>
  <div class="mb-2">
    <JouleAutoComplete :error-text="stepValidationStore.getErrorByFieldName('lieferadresse.plz')"
                       label="Wie lautet Ihre Postleitzahl?"
                       placeholder="Postleitzahl"
                       v-model="plz"
                       :input-valid="stepValidationStore.akquiuseDatenValidation.lieferadressePlzValid"
                       :query="query"
                       :input-formatter="formatInput"
                       :result-formatter="formatInput"
                       :queryInitial="akquiseDatenStore.akquiseDaten.lieferadresse.plz && akquiseDatenStore.akquiseDaten.lieferadresse.plz.length > 0 &&
                        (!akquiseDatenStore.akquiseDaten.lieferadresse.ort || akquiseDatenStore.akquiseDaten.lieferadresse.ort.length === 0)"
                       @input-changed="plzChanged"></JouleAutoComplete>
  </div>
  <div class="mb-2 flex-row non-aligned gap-1">
    <JouleIncrementInput
        :data="VerbrauchsVorauswahl.laufLeistungData"
        class="input"
        button-header="Wie hoch ist Ihre Laufleistung?"
        icon="directions_car"
        v-model="akquiseDatenStore.akquiseDaten.verbrauch"
        @inputChanged="userChangedInput"
    ></JouleIncrementInput>

    <JouleInputNumber
        class="input"
        name="verbrauch"
        button-header="Wie hoch ist ihr Verbrauch?"
        placeholder="Verbrauch in kWh"
        suffix=" kWh"
        :error-text="stepValidationStore.getErrorByFieldName('verbrauch')"
        v-model="akquiseDatenStore.akquiseDaten.verbrauch"
        @inputChanged="userChangedInput"
        icon="bolt"
    ></JouleInputNumber>
  </div>
  <div>
    <div class="mb-2">
      <JouleInputSwitch
          input-id="eigenerZaehlerInputSwitch"
          class="mb-1"
          :inverted="true"
          v-model="akquiseDatenStore.akquiseDaten.gemeinsameMessung"
          label="Der Ladepunkt hat einen eigenen Zähler"
          tooltip="Mit einem eigenen Zähler für den Ladepunkt wird Ihr Autostrom separat vom Hausstrom gemessen. Damit profitieren Sie von Vergünstigungen in den Entgelten und Abgaben."
      ></JouleInputSwitch>
      <ContentBox
          v-if="
          akquiseDatenStore.akquiseDaten.gemeinsameMessung ||
          !akquiseDatenStore.akquiseDaten.gemeinsameMessung == null
        "
      >
        <template #content>
          <span
          >Kein separater Zähler für Ihren Ladepunkt? Kein Problem! Geben Sie
            bei der Verbrauchsangabe einfach Ihren
            <b>Gesamtverbrauch inkl. des Verbrauchs an Autostrom</b> ein.</span
          >
        </template>
      </ContentBox>
    </div>
  </div>
  <TarifrechnerAktionscode @enter="$emit('enter')"></TarifrechnerAktionscode>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.input {
  flex: 1;
}
</style>

import {IAkquiseDaten} from "@/types/AkquiseDaten";
import {AkquiseDatenValidationModel} from "@/types/AkquiseDatenValidationModel";

export const getAkquiseDaten = async (): Promise<IAkquiseDaten> => {
    return fetch("/api/akquise-daten", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return null;
        }
    });
};

export const getAkquiseDatenByVerifizierungsCode = async (verifizierungsCode: string): Promise<IAkquiseDaten> => {
    return fetch("/api/akquise-daten/verifizierungs-code/" + verifizierungsCode, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return null;
        }
    });
};

export const verifyEmail = async (verifizierungsCode: string): Promise<IAkquiseDaten> => {
    return fetch("/api/akquise-daten/verifizierungs-code/" + verifizierungsCode + "/verify", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return null;
        }
    });
};

export const postAkquiseDaten = async (akquiseDaten: IAkquiseDaten): Promise<IAkquiseDaten> => {
    return fetch("/api/akquise-daten", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(akquiseDaten)
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return null;
        }
    });
};

export const validateSession = async (): Promise<AkquiseDatenValidationModel> => {
    return await fetch("/api/akquise-daten/validate", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return null;
        }
    });
}

export const sendVerifizierung = async (): Promise<boolean> => {
    return await fetch("/api/akquise-daten/send-verifizierungs-mail", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return null;
        }
    });
}

export const resendVerifizierung = async (): Promise<boolean> => {
    return await fetch("/api/akquise-daten/resend-verifizierung", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return null;
        }
    });
}

export const confirmOrder = async (akquiseSession: string, grundpreis: number, arbeitspreis: number): Promise<IAkquiseDaten> => {
    return await fetch("/api/akquise-daten/finishOrder/" + akquiseSession, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({grundpreis: grundpreis, arbeitspreis: arbeitspreis})
    }).then((o: Response) => {
        if (o.status === 200) {
            return o.json();
        } else {
            return null;
        }
    });
}

<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {ChartData, ChartDataset, ChartOptions, Point} from 'chart.js';
import Chart from "primevue/chart";
import dayjs from 'dayjs';
import {centValueFormatter} from '@/utils/NumberFormatter';
import {generateTooltip} from '@/utils/ChartTooltipGenerator';
import {findEnergyPrices} from "@/api/EnergyPriceApi";
import {EnergyPrice} from "@/types/EnergyPrice";
import FailedChartSkeleton from "@/components/FailedChartSkeleton.vue";
import LoadingChartSkeleton from "@/components/LoadingChartSkeleton.vue";
const chartData = ref({} as ChartData);
const chartOptions = ref({} as ChartOptions);
const plugins: any = [];
const loading = ref(false);
const dataLoaded = ref(false);
const dataError = ref(false);

const fetchData = async () => {
  try {
    const response: EnergyPrice[] = await findEnergyPrices();
    loading.value = true;

    dataLoaded.value = true;

    if (!response?.length) {
        dataError.value = true;
        return;
      }
    
    dataError.value = false;

    let layoutPrepared = false;
    const maxPrice = Math.max(...response.map((e) => e.exchangePrice ?? 0));
    const minPrice = Math.min(...response.map((e) => e.exchangePrice ?? 0));
    const minSensibleInterval = 5;
    const customStepCount = 3;
    const customStepSize =
        Math.ceil(maxPrice / customStepCount / minSensibleInterval) * minSensibleInterval;

    chartOptions.value = {
      devicePixelRatio: 4,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: 'x',
      },
      scales: {
        x: {
          type: 'linear',
          min: -0.5,
          max: 23.5,
          border: {display: false},
          ticks: {
            stepSize: 0.1, // needs to be low to accomodate for strange chartjs step skips
            callback: function (val) {
              let number: number;
              if (typeof val === 'number') {
                number = val;
              } else {
                number = +val;
              }
              number = Math.floor(number * 10) / 10; // round number because of chartjs rounding error

              return number % 4 === 0 ? this.getLabelForValue(number) : null;
            },
            color: '#888A8B',
            font: {
              size: 14,
              family: 'Avenir Next',
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          border: {
            display: false,
          },
          ticks: {
            count: customStepCount + 1,
            color: '#888A8B',
            font: {
              size: 14,
              family: 'Avenir Next',
            },
            stepSize: customStepSize,
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          labels: {
            color: '#495057',
          },
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          padding: 9,
          external: (ctx) => {
            generateTooltip(ctx, ['Börsenpreis']);
          },
          callbacks: {
            title: function (tooltipItems) {
              if (tooltipItems && tooltipItems.length > 0) {
                const dataIndex = tooltipItems[0].dataIndex - 1;
                return `${dataIndex} Uhr`;
              }
              return '- Uhr';
            },
            label: function (tooltipItem) {
              const index = tooltipItem.dataIndex - 1;
              const data = response[index];
              const labelRows = [
                centValueFormatter(data.exchangePrice),
              ];
              return labelRows;
            },
            // footer: function (tooltipItems) {
            //   if (tooltipItems && tooltipItems.length > 0) {
            //     const dataIndex = tooltipItems[0].dataIndex - 1;
            //     const data = response[dataIndex];
            //     return centValueFormatter(data.total);
            //   }
            //   return '';
            // },
          },
        },
      },
    };

    let datasets: ChartDataset[] = [
      {
        label: 'Price',
        data: prepData(response.map((e) => e.exchangePrice ?? 0)),
        fill: false,
        borderWidth: 2,
        tension: 0,
        stepped: 'middle',
        pointRadius: 0,
        pointBorderWidth: 8,
        pointStyle: 'rectRounded',
        pointBorderColor: 'transparent',
        pointHoverBorderColor: '#545759',
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#545759',
        pointHitRadius: 5,
      } as any,
      {
        label: 'Price',
        data: prepData(response.map((e) => e.exchangePrice ?? 0)),
        fill: false,
        borderWidth: 2,
        tension: 0,
        stepped: 'middle',
        pointRadius: 0,
        pointBorderWidth: 8,
        pointStyle: 'rectRounded',
        pointBorderColor: 'transparent',
        pointHoverBorderColor: '#545759',
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#545759',
        pointHitRadius: 5,
      } as any];
    if (response.find((e) => e.exchangePrice < 0)) {
      datasets.push({
        label: 'Price',
        data: prepData(response.map((e) => e.exchangePrice ?? 0)),
        fill: false,
        borderWidth: 2,
        tension: 0,
        stepped: 'middle',
        pointRadius: 0,
        pointBorderWidth: 8,
        pointStyle: 'rectRounded',
        pointBorderColor: 'transparent',
        pointHoverBorderColor: '#545759',
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#545759',
        pointHitRadius: 5,
      } as any);

      datasets.push({
        type: 'line',
        data: new Array(24).fill(0),
        borderColor: '#CBCCCD',
        borderDash: [5, 2],
        borderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 0,
      } as any);
    }
    chartData.value = {
      labels: response.map((e) => [dayjs(new Date(e.time)).format('H') ?? 'N.A.']),
      datasets: datasets,
    };

    plugins.push({
      afterDraw: function (chart: any) {
        // Dynamic Line Color Gradient
        if (!layoutPrepared) {

          let ctx = chart.ctx;
          let offset = 0;
          if (ctx !== null) {
            // if (chart.scales.y.max > 1.5) {
            ctx.save();
            const yAxis = chart.scales.y;
            const thresholdValue = minPrice + (maxPrice - minPrice) / 2;
            const yThreshold = yAxis.getPixelForValue(thresholdValue < 0 ? 0 : thresholdValue);
            const gradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom);
            gradient.addColorStop(0, '#FF6259');
            offset = (yThreshold - yAxis.top) / (yAxis.bottom - yAxis.top);
            gradient.addColorStop(offset, '#FF6259');
            gradient.addColorStop(offset, '#0E9D6E');
            gradient.addColorStop(1, '#0E9D6E');
            chart.data.datasets[0].borderColor = gradient;
            ctx.restore();
            offset >= 0 ? (layoutPrepared = true) : (layoutPrepared = false); // set true to only run once
          }
        }
      }
    });


    const roundedMax = Math.ceil(maxPrice / 10) * 10;
    chartOptions.value.scales!.y!.max = Math.min(roundedMax, 100);
  } catch (error) {
    console.log(error);
    loading.value = false;
  }
};

const prepData = function (arr: number[]): Point[] {
  let newArr: Point[] = [];
  for (let i = 0; i < arr.length; i++) {
    newArr.push({x: i, y: arr[i]});
  }
  newArr.unshift({x: -1, y: newArr[0].y});
  newArr.push({x: newArr.length, y: newArr[newArr.length - 1].y});
  return newArr;
};

onMounted(async () => {
  await fetchData();
});
</script>

<template>

  <div class="content-wrapper chart-wrapper">
    <FailedChartSkeleton
        v-if="dataError"
        chart-type="line"
        fail-text="Leider gibt es noch keine Daten zu Ihrem Strompreis"
    ></FailedChartSkeleton>
    <Chart v-else-if="dataLoaded" type="line" class="widget-chart" :data="chartData" :options="chartOptions" :plugins="plugins"></Chart>
    <LoadingChartSkeleton
        v-else
        chart-type="line"
    ></LoadingChartSkeleton>
  </div>
</template>

<style scoped lang="scss">
.content-wrapper.chart-wrapper {
  height: 224px;
}
</style>

<script setup lang="ts">
import Card from "primevue/card";
import JouleSelectButton from "@/components/JouleSelectButton.vue";
import {onMounted, ref} from "vue";
import {useStore} from "@/store/AkquiseDatenStore";
import router from "@/router";
import JouleButton from "@/components/JouleButton.vue";
import TarifrechnerStrom from "@/components/TarifrechnerStrom.vue";
import TarifrechnerWaermestrom from "@/components/TarifrechnerWaermestrom.vue";
import TarifrechnerAutostrom from "@/components/TarifrechnerAutostrom.vue";
import {getLabelBySparteEnum, SparteEnum} from "@/types/SparteEnum";
import {VerbrauchsVorauswahl} from "@/types/VerbrauchVorauswahl";
import {handleBasicJentis, buttonClicked} from "@/utils/JentisService";

handleBasicJentis();

const sparteOptions = [
  {label: getLabelBySparteEnum(SparteEnum.STROM), value: SparteEnum.STROM},
  {
    label: getLabelBySparteEnum(SparteEnum.WAERMEPUMPE),
    value: SparteEnum.WAERMEPUMPE,
  },
  {
    label: getLabelBySparteEnum(SparteEnum.AUTOSTROM),
    value: SparteEnum.AUTOSTROM,
  },
];
const sparte = ref(sparteOptions[0].value);
const akquiseDatenStore = useStore();

const calculateTariff = async () => {
  await buttonClicked('strompreis_berechnen_clicked');
  await akquiseDatenStore.postAkquiseDaten();
  await router.push({name: "TarifrechnerDetail"});
};

onMounted(() => {
  userChangedUsage.value = akquiseDatenStore.akquiseDaten.verbrauch != null;
  if (akquiseDatenStore.akquiseDaten.sparte) {
    sparte.value =
        akquiseDatenStore.akquiseDaten.sparte == SparteEnum.STROM ||
        akquiseDatenStore.akquiseDaten.sparte == SparteEnum.AUTOSTROM
            ? akquiseDatenStore.akquiseDaten.sparte
            : SparteEnum.WAERMEPUMPE;
  } else {
    sparte.value = sparteOptions[0].value;
    akquiseDatenStore.akquiseDaten.sparte = SparteEnum.STROM;
  }
});
const sparteVerbrauch = VerbrauchsVorauswahl;
const userChangedUsage = ref(false as boolean);
const buttonChange = () => {
  akquiseDatenStore.akquiseDaten.sparte = sparte.value;
  if (!userChangedUsage.value) {
    switch (akquiseDatenStore.akquiseDaten.sparte) {
      case SparteEnum.AUTOSTROM:
        akquiseDatenStore.akquiseDaten.verbrauch =
            sparteVerbrauch.laufLeistungData[2].value;
        break;
      case SparteEnum.WAERMEPUMPE || SparteEnum.NACHTSPEICHERHEIZUNG:
        akquiseDatenStore.akquiseDaten.verbrauch =
            sparteVerbrauch.flaecheData[1].value;
        break;
      case SparteEnum.STROM:
        akquiseDatenStore.akquiseDaten.firma ? akquiseDatenStore.akquiseDaten.verbrauch = null : akquiseDatenStore.akquiseDaten.verbrauch =
            sparteVerbrauch.personData[1].value;
        break;
    }
  }
};

</script>

<template>
  <div class="card-wrapper">
    <Card class="card">
      <template #title>
        <div class="card-headline">TARIFRECHNER</div>
      </template>
      <template #content>
        <div class="subline-text">
          Sichern Sie sich Ihren GP JOULE-Tarif mit 100% Grünstrom.
        </div>
        <div
            v-if="akquiseDatenStore.hideVarianteSelect"
            class="mb-2 flex-row justify-start cursor-pointer hover-field"
            @click="akquiseDatenStore.hideVarianteSelect = false"
        >
          <span class="mr-1"
          >Kategorie:
            <span class="underline">{{
                getLabelBySparteEnum(akquiseDatenStore.akquiseDaten.sparte)
              }}</span></span
          >
          <i class="material-icons-round font-size-20 edit-grey">edit</i>
          <div class="tooltip-overlay tooltip-font">
            <div class="tooltip-text">Bearbeiten</div>
            <div class="pseudo-hover-overlay"></div>
          </div>
        </div>
        <div class="mb-2" v-if="!akquiseDatenStore.hideVarianteSelect">
          <JouleSelectButton
              v-model="sparte"
              :options="sparteOptions"
              label="Wofür soll der Strom verwendet werden?"
              @changed="buttonChange"
              tooltip="Wählen Sie Ihr Stromprodukt aus:<br>• Strom für Ihre Wohn- oder Gewerbefläche <br>• Wärmestrom für Ihre Wärmepumpe oder Nachtspeicherheizung <br>• Autostrom für Ihren Ladepunkt vom E-Auto"
          >
          </JouleSelectButton>
        </div>
        <TarifrechnerStrom
            v-if="akquiseDatenStore.akquiseDaten.sparte == SparteEnum.STROM"
            @user-changed-input="userChangedUsage = true"
            @button-change="buttonChange" @enter="calculateTariff"
        ></TarifrechnerStrom>
        <TarifrechnerWaermestrom
            v-if="
            akquiseDatenStore.akquiseDaten.sparte == SparteEnum.WAERMEPUMPE ||
            akquiseDatenStore.akquiseDaten.sparte ==
              SparteEnum.NACHTSPEICHERHEIZUNG
          "
            @user-changed-input="userChangedUsage = true"
        ></TarifrechnerWaermestrom>
        <TarifrechnerAutostrom
            v-if="akquiseDatenStore.akquiseDaten.sparte == SparteEnum.AUTOSTROM"
            @user-changed-input="userChangedUsage = true"
        ></TarifrechnerAutostrom>

        <JouleButton
            label="Strompreis berechnen"
            @click="calculateTariff"
        ></JouleButton>
      </template>
    </Card>
    <Card
        v-if="akquiseDatenStore.akquiseDaten.firma"
        class="card no-card-padding mt-60-px"
    >
      <template #content>
        <div>
          <span class="card-content-text mr-1"
          >Haben Sie einen RLM-Zähler?</span
          >
          <a
              class="card-content-text"
              href="https://www.gp-joule.com/de/strom-fuer-industriekunden/kontaktformular"
              target="_blank"
          >Angebot einholen</a
          >
        </div>
      </template>
    </Card>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.edit-grey {
  color: var(--gpj-grey, #545759);
}

.hover-field:hover {
  .tooltip-overlay {
    display: block;
  }
}

.tooltip-text {
  margin-top: -12px;
}
</style>

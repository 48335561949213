<script setup lang="ts">

import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import ContentBox from "@/components/ContentBox.vue";
import router from "@/router";
import PriceChart from "@/components/PriceChart.vue";
import TariffOption from "@/components/TariffOption.vue";
import {useStore} from "@/store/AkquiseDatenStore";
import {useStore as tarifStore} from "@/store/TarifDatenStore";
import {computed, onMounted, ref} from "vue";
import {SparteEnum} from "@/types/SparteEnum";
import JouleTooltip from "@/components/JouleTooltip.vue";
import TarifError from "@/components/TarifError.vue";
import {handleSimpleJentis} from "@/utils/JentisService";

handleSimpleJentis();

const store = useStore();
const tStore = tarifStore()
const collapsed = ref(true)
onMounted(async () => {
  if (store.akquiseDaten.akquiseSession) {
    await tStore.getTarifDaten(store.akquiseDaten.akquiseSession);
  }
})

const sparteLabel = computed((): string => {
  if (store.akquiseDaten.sparte === SparteEnum.STROM) {
    if (store.akquiseDaten.firma) {
      return 'Gewerbestrom für Geschäftskunden '
    } else {
      return 'Strom für Privatkunden '
    }
  } else if (store.akquiseDaten.sparte === SparteEnum.WAERMEPUMPE || store.akquiseDaten.sparte === SparteEnum.NACHTSPEICHERHEIZUNG) {
        if (store.akquiseDaten.firma) {
      return 'Wärmestrom für Geschäftskunden '
    } else {
      return 'Wärmestrom für Privatkunden '
    }
  } else if (store.akquiseDaten.sparte === SparteEnum.AUTOSTROM) {
        if (store.akquiseDaten.firma) {
      return 'Autostrom für Geschäftskunden '
    } else {
      return 'Autostrom für Privatkunden '
    }
  } else {
    return '';
  }
})

</script>

<template>
  <div class="card-wrapper">
    <div class="card">
      <Card>
        <template #title>
          <div class="card-headline-small">Ihre Angaben</div>
        </template>
        <template #content>
          <div class="content-wrapper">
            <content-box :show-edit-button="true" @edit-button-clicked="router.push('/tarifrechner')">
              <template v-slot:content>
                <div class="box-content-wrapper">
                  <div class="box-portion">
                    <div class="material-icons-round">person</div>
                    <div class="content-box-text-bold-grey">
                      {{ sparteLabel }}
                      in
                      {{ store.akquiseDaten.lieferadresse.plz }} {{ store.akquiseDaten.lieferadresse.ort }}
                    </div>
                  </div>
                  <div class="box-portion">
                    <div class="material-icons-round">bolt</div>
                    <div class="content-box-text-bold-grey">{{ store.akquiseDaten.verbrauch?.toLocaleString('de') }} kWh im Jahr</div>
                  </div>
                  <div class="box-portion" v-if="store.akquiseDaten.aktionscode">
                    <div class="material-icons-round">confirmation_number</div>
                    <div class="content-box-text-bold-grey">{{ store.akquiseDaten.aktionscode }}
                      ({{ store.akquiseDaten.aktionscodeSuccessMeldung }})
                    </div>
                  </div>
                </div>
              </template>
            </content-box>
          </div>
        </template>
      </Card>
      <Card>
        <template #title>
          <div class="card-headline-small">Wählen Sie Ihren GP JOULE Tarif</div>
        </template>
        <template #content>
          <div v-if="tStore.loading" class="tarif-wrapper">
            <div class="tariff-skeleton">
              <Skeleton  class="mb-1" shape="rectangle" height="30px" width="30%"></Skeleton>
              <Skeleton  class="mb-1" shape="rectangle" height="40px" width="50%"></Skeleton>
              <Skeleton  class="mb-1" shape="rectangle" height="40px" width="100%"></Skeleton>
              <Skeleton  shape="rectangle" height="185px" width="100%"></Skeleton>
            </div>
            <div class="tariff-skeleton">
              <Skeleton  class="mb-1" shape="rectangle" height="30px" width="30%"></Skeleton>
              <Skeleton  class="mb-1" shape="rectangle" height="40px" width="50%"></Skeleton>
              <Skeleton  class="mb-1" shape="rectangle" height="40px" width="100%"></Skeleton>
              <Skeleton  shape="rectangle" height="185px" width="100%"></Skeleton>
            </div></div>
          <div v-if="!tStore.loading" class="tarif-wrapper">
            <div v-for="tarif in tStore.tarife" class="tarif-option" v-bind:key="tarif.tarifname">
              <tariff-option v-model="store.akquiseDaten.tarifId"
                             :collapsed="collapsed"
                             @toggle-collapsed="collapsed = !collapsed"
                             :option="tarif"></tariff-option>
            </div>
            <tarif-error v-if="tStore.tarife.length == 0 && !tStore.loading"></tarif-error>
          </div>
        </template>
      </Card>
      <Card>
        <template #title>
          <div id="boersenpreis" class="headline">
            <div class="card-headline-small">Heutiger Börsenstrompreis</div>
            <JouleTooltip text="Die Börsenstrompreise variieren stündlich. Mit dem flex Tarif können Sie von günstigen Börsenstrompreisen profitieren."></JouleTooltip>
          </div>
          <span class="card-sub-headline">pro Stunde (in Cent je kWh)</span>
        </template>
        <template #content>
          <div class="content-wrapper">
            <PriceChart></PriceChart>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1080px;
  color: black;
  gap: 60px;
}

.tarif-wrapper {
  display: flex;
  flex-direction: row;
  gap: 2em;
  flex-wrap: wrap;
}

.tarif-option {
  flex: 1;
}

.box-content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  gap: 20px
}

.box-portion {
  min-width: 450px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--GPJ-Grey, #545759);
}

@media all and (max-width: 575px) {
  .box-portion {
    min-width: 0;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.headline {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}
.skeleton-wrapper{
  display: flex;
}
.tariff-skeleton{
  flex:1;
  border-radius: 8px;
  border: 2px solid var(--GPJ-Black, #000);
  padding: 15px;

}
@media only screen and (max-width: 769px) {
  .card {
    max-width: 540px;
  }
}
</style>
